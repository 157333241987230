import { Button, Divider, Form, message, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, setDisableImg } from '../../app/reducers/formSlice';
import { showConfirmModal, showErrMsg } from '../../utils';
import './ModalForm.scss';
import useDetailHistory from '../../pages/admin/EditedHistory/useDetailHistory';

export default function ModalForm({
	children,
	onSubmit,
	layout = 'vertical',
	handleClose,
	form,
	title,
	isAdd,
	successMessage,
	handleChange,
	disabledFeature,
	mutate,
	approve,
	record,
	setFormType,
	disabled,
	submitBtnProps = {},
	isModified,
	isApprove,
	moreButtons,
	isEditHistories = false,
	width,
	isPending = true,
	data,
	...props
}) {
	const { visible, isModify, isChangeImg } = useSelector(state => state.form);
	const { roles } = useSelector(state => state.auth);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const close = () => {
		if (typeof handleClose === 'function') {
			handleClose();
		}
		form.resetFields();
		dispatch(closeModal());
	};
	const closeForm = () => {
		if (form.isFieldsTouched()) {
			showConfirmModal({
				title: t('message.modalDelete'),
				onOk: close
			});
		} else {
			close();
		}
		dispatch(setDisableImg());
	};

	const submitForm = async values => {
		if (typeof onSubmit === 'function') {
			setLoading(true);
			try {
				await onSubmit(values);
				close();
				message.success(successMessage ?? (isAdd ? t('message.success') : t('message.editRequestSuccess')));
			} catch (error) {
				showErrMsg(error);
			} finally {
				setLoading(false);
			}
		}
	};
	const getContentSubmitBtn = () => {
		if (isAdd) {
			return t('common.btn_submit_default');
		} else if (isApprove) {
			return t('admin.approved');
		} else if (isModify || isModified) {
			return t('common.modify');
		} else {
			return;
		}
	};

	const columnHistory = useDetailHistory();

	return (
		<Modal
			centered
			width={width}
			title={title}
			isModify={isModify}
			isApprove={isApprove}
			className='modal-form'
			footer={false}
			onCancel={closeForm}
			open={visible}>
			<>
				<Form disabled={disabled} form={form} labelAlign='left' layout={layout} onFinish={submitForm} {...props}>
					{children}
					<div className='modal-form-submit'>
						<Form.Item shouldUpdate noStyle>
							<div className='btn-send'>
								<span className='require-text'>
									Những trường đánh dấu (<span className='red-star'>*</span>) là bắt buộc
								</span>
								{moreButtons}
								<Button
									data-testid='form-drawer_submit'
									htmlType='submit'
									loading={loading}
									disabled={disabled}
									type='primary'
									{...submitBtnProps}>
									{getContentSubmitBtn()}
								</Button>
							</div>
						</Form.Item>
					</div>
				</Form>
				{isEditHistories && (
					<>
						<Divider>{t('common.edited_history')}</Divider>
						<Table dataSource={data?.data} columns={columnHistory} />
					</>
				)}
			</>
		</Modal>
	);
}
