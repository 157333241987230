import useSWR, { SWRConfig } from 'swr';
import axiosClient from '../../api/axiosClient';
import { useFilter } from './useFilter';
import { queryStringify } from '../../utils';
import { useMemo } from 'react';

const fetcher = (url, query) => {
	return axiosClient.get(`${url}${query ? '?' + query : ''}`);
};

export function FetchProvider({ children }) {
	return (
		<SWRConfig
			value={{
				revalidateIfStale: false,
				revalidateOnFocus: false,
				revalidateOnReconnect: false,
				shouldRetryOnError: false,
				fetcher
			}}>
			{children}
		</SWRConfig>
	);
}

export default function useFetch({ url, defaultParams = {}, withQuery = true, requiredParams = [] }, options) {
	const { filter, search } = useFilter();

	const queryKey = useMemo(() => {
		const condition = requiredParams?.every(item => filter[item]) && !url?.includes('/undefined');

		if (!condition) {
			return null;
		}
		let newParams = defaultParams;
		if (withQuery) {
			newParams = {
				...newParams,
				...filter
			};
		}

		return [url, queryStringify(newParams)];
	}, [url, search]);

	const { data, error, isValidating, mutate } = useSWR(queryKey, options);

	return { data: data?.data, error, loading: !error && !data, mutate, isValidating };
}
