import { Checkbox, DatePicker, Form, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFilter } from '../../common/hooks';
import { DATE_FORMAT, ROLE } from '../../constant';
import { ACRONYM_TYPE, MEMBERS, URL_ACCEPTED_BY } from '../../constant/common/api';
import RangePick from '../CommonFields/RangePick';
import UserSelect from '../CommonFields/UserSelect';
import { formatDisplayDate } from '../../utils/dateTime';
import './Tabs.scss';

const { RangePicker } = DatePicker;

export default function TabsCustomed({ data, propsTab, ChangeStatus }) {
	const { roles } = useSelector(state => state.auth);
	const { filter, setFilter } = useFilter();

	const { TabPane } = Tabs;
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const checkedBox = roles?.includes(ROLE.COSIC_LEADER);

	const handleSubmit = values => {
		let newValues;
		if (values.time) {
			const dateStart = formatDisplayDate(values.time[0]);
			const dateEnd = formatDisplayDate(values.time[1]);
			newValues = { ...values, dateStart, dateEnd };
		} else {
			newValues = { ...values };
		}

		delete newValues.time;
		setFilter(newValues);
	};

	useEffect(() => {
		const newValues = {
			...filter,
			assignToMe: checkedBox
		};
		setFilter(newValues);
		form.setFieldsValue(newValues);
	}, []);
	return (
		<Tabs
			destroyInactiveTabPane
			className='custom-tabs'
			tabBarExtraContent={{
				right: [
					<div key='approveTab'>
						<Form key='Form' layout='inline' form={form} style={{ marginTop: '8px' }} onFinish={handleSubmit}>
							<Form.Item name='assignToMe' valuePropName='checked'>
								<Checkbox
									className='assign-me'
									style={{ marginRight: '8px', marginTop: '5px' }}
									onChange={e => setFilter({ ...filter, assignToMe: e.target.checked })}>
									{t('filter.assign_me')}
								</Checkbox>
							</Form.Item>

							<Form.Item label={t('common.setup_time')} name='time'>
								<RangePick keyName='time' valueKey='time' format={DATE_FORMAT} />
							</Form.Item>
							<Form.Item name='member'>
								<UserSelect
									search={true}
									keyName='member'
									placeHolder={t('common.search_member')}
									url={MEMBERS}
									titleKey='fullName'
									valueKey='username'
								/>
							</Form.Item>
							<Form.Item name='acceptedBy'>
								<UserSelect
									search={true}
									keyName='acceptedBy'
									placeHolder={t('common.search_approver')}
									url={`${URL_ACCEPTED_BY}/leaders`}
									titleCallback={obj => {
										return `${obj.firstName} ${obj.lastName}`;
									}}
									titleKey='userFullName'
									valueKey={'userName'}
								/>
							</Form.Item>
							<Form.Item name='offTypeAcronymSet'>
								<UserSelect
									search={true}
									keyName='offTypeAcronymSet'
									placeHolder={t('common.search_request')}
									url={ACRONYM_TYPE}
									titleKey='name'
									valueKey={'acronym'}
								/>
							</Form.Item>
						</Form>
					</div>
				]
			}}
			{...propsTab}
			onTabClick={ChangeStatus}>
			{data.map(tab => (
				<TabPane tab={tab.title} key={tab.key}>
					{tab.content}
				</TabPane>
			))}
		</Tabs>
	);
}
