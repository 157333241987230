import moment from 'moment';

export const SESSION_TOKEN_KEY = 'sessionToken';
export const AUTH_BEARER_TOKEN_KEY = 'Auth2bearer';
export const ROLE = {
	COSIC_SUPER_ADMIN: 'COSIC_ROLE_SUPER_ADMIN',
	COSIC_LEADER: 'COSIC_ROLE_LEADER',
	COSIC_ADMIN: 'COSIC_ROLE_ADMIN',
	COSIC_USER: 'COSIC_ROLE_USER',
	COSIC_REVIEWER: 'COSIC_ROLE_REVIEWER',
	COSIC_ACCOUNTANT: 'COSIC_ROLE_ACCOUNTANT'
};

export const MAX_DISPLAY_LEN = 30;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const HOUR_FORMAT = 'HH:mm DD/MM/YYYY';
export const FORMAT_HH_MM = 'HH:mm';

export const TYPE_COLORS = { 1: 'green', 2: 'red', 3: 'blue', 4: 'purple', 5: 'yellow', 6: 'orange' };
export const ASC = 'ASC';
export const DESC = 'DESC';
export const ASCEND = 'ascend';
export const DESCEND = 'descend';
export const WORKTIME = 4;
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;
export const MONTH = 'MM';
export const YEAR = 'YYYY';
export const REQUESTS_TYPE = {
	half_date: '1/2',
	checkin: 'Ci',
	checkout: 'Cout',
	late: 'L',
	early: 'E',
	Re: 'RE',
	OT: 'OT',
	CP: 'CP'
};
export const PENDING = 'Chờ duyệt';
export const ACCEPT = 'Đã duyệt';
export const CANCEL = 'Đã hủy';
export const CANCELED = 'Huỷ bỏ';
export const REJECT = 'Từ chối';
export const REVIEWED = 'Đã xác nhận';
export const FORMAT_MONTH = 'MM/YYYY';
export const FORMAT_DD_MM = 'DD/MM';
export const TODAY = moment(new Date(), DATE_FORMAT);
export const TIME_OT = ['1.0', '2.0', '3.0', '4.0', '5.0'];
export const MORNING = 'Sáng';
export const AFTERNOON = 'Chiều';
export const LIST_STATUS = [
	{ text: 'Nghỉ', value: 'Nghỉ' },
	{ text: 'Đi làm', value: 'Đi làm' },
	{ text: 'Remote', value: 'Remote' }
];
export const STATUS_PENDING = 'PENDING,REVIEWED';
export const STATUS_COMPLETE_REJECT = 'COMPLETE,REJECT,CANCEL,REVIEWED';
export const CHECK_STATUS_APPROVE = 'approved';
export const allRole = [
	{ value: 'COSIC_ROLE_ADMIN', label: 'Admin', color: 'magenta' },
	{ value: 'COSIC_ROLE_SUPER_ADMIN', label: 'Super Admin', color: 'gold' },
	{ value: 'COSIC_ROLE_REVIEWER', label: 'Reviewer', color: 'cyan' },
	{ value: 'COSIC_ROLE_LEADER', label: 'Leader', color: 'blue' },
	{ value: 'COSIC_ROLE_USER', label: 'User', color: 'green' }
];
export const displaySuperAdmin = ['COSIC_ROLE_SUPER_ADMIN', 'COSIC_ROLE_ADMIN'];
export const displayLeaderApprove = ['COSIC_ROLE_SUPER_ADMIN', 'COSIC_ROLE_ADMIN', 'COSIC_ROLE_REVIEWER', 'COSIC_ROLE_LEADER'];
export const displayReview = ['COSIC_ROLE_SUPER_ADMIN', 'COSIC_ROLE_ADMIN', 'COSIC_ROLE_REVIEWER'];
export const statusAccountant = [
	{ value: 'Đã tạo', color: 'green' },
	{ value: 'Đã gửi', color: 'orange' }
];
