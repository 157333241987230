import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import i18n from '../../translation/i18n';

const { confirm } = Modal;
const showModalConfirm = ({ onOk, ...props }) => {
	confirm({
		maskClosable: true,
		icon: <ExclamationCircleOutlined />,
		okText: i18n.t('common.yes'),
		cancelText: i18n.t('common.no'),
		onOk,
		onCancel() {},
		...props
	});
};

export default showModalConfirm;
