import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	visible: false,
	id: null,
	data: {},
	record: null,
	requestType: 'Ci',
	isModify: false,
	userInfoVisible: false,
	isEditImg: false,
	isChangeImg: false
};

export const formSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		openModal: (state, { payload }) => {
			state.visible = true;
			if (payload && payload !== state.id) {
				state.id = payload;
			}
		},
		openModalProcessed: (state, { payload }) => {
			state.visible = true;
			if (payload && payload !== state.id) {
				state.id = payload;
			}
		},
		openHistoriesModal: (state, { payload }) => {
			state.visible = true;
			if (payload && payload !== state.id) {
				state.id = payload;
			}
		},
		openModalUserInfo: (state, { payload }) => {
			state.userInfoVisible = true;
			if (payload && payload !== state.id) {
				state.id = payload;
			}
		},
		closeModal: state => {
			state.visible = false;
			state.id = null;
		},
		closeModalUserInfo: state => {
			state.userInfoVisible = false;
			state.id = null;
		},
		setRequestType: (state, {payload}) => {
			state.requestType = payload;
		},
		setOpenModify: (state, {payload}) => {
			state.isModify = payload;
		},
		setCloseModify: state => {
			state.isModify = false;
		},
		setEnableImg: state => {
			state.isEditImg = true;
		},
		setRecord: (state, {payload}) => {
			state.record = payload;
		},
		setDisableImg: state => {
			state.isEditImg = false;
		},
		setChangeImgTrue: state => {
			state.isChangeImg = true;
		},
		setChangeImgFalse: state => {
			state.isChangeImg = false;
		}
	}
});

export const {
	setDisableImg,
	setEnableImg,
	openModalUserInfo,
	closeModalUserInfo,
	openModal,
	closeModal,
	setRequestType,
	setOpenModify,
	setRecord,
	setCloseModify,
	setChangeImgTrue,
	setChangeImgFalse,
	openHistoriesModal,
	openModalProcessed
} = formSlice.actions;

export default formSlice.reducer;
