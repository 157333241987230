import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilter } from '../../../common/hooks';
import { DATE_FORMAT, FORMAT_DD_MM, FORMAT_MONTH, TYPE_COLORS } from '../../../constant';

function useCreateCols() {
	const { t } = useTranslation();
	const { filter } = useFilter();
	return useMemo(() => {
		const today = moment();
		const filterMonth = moment(filter?.date, FORMAT_MONTH).startOf('month');
		const endOfMonth = filterMonth.clone().endOf('month');

		const cols = [
			{
				title: t('common.name'),
				align: 'center',
				width: '10rem',
				dataIndex: 'fullName',
				sorter: true,
				sticky: true,
				fixed: 'left'
			}
		];

		for (; filterMonth < today && filterMonth < endOfMonth; filterMonth.add(1, 'day')) {
			const colDay = filterMonth.clone();
			const isWeekend = colDay.day() === 0 || colDay.day() === 6;
			cols.push({
				title: colDay.format(FORMAT_DD_MM),
				className: isWeekend ? 'color-info' : '',
				align: 'center',
				width: '5rem',
				dataIndex: 'workDays',
				render: workDays => {
					if (Array.isArray(workDays)) {
						const workDay = workDays.find(day => day?.dateAt === colDay.format(DATE_FORMAT));
						const extraRequests = workDay?.requests;
						return (
							<>
								<Tooltip title={workDay?.offName} placement='top'>
									<Tag style={{ margin: '4px' }} color={TYPE_COLORS[workDay?.offColor]}>
										{workDay?.offAcronym}
									</Tag>
								</Tooltip>

								{extraRequests
									? extraRequests.map((extraRequest, key) => (
											<Tooltip title={extraRequest?.name} placement='top' key={key}>
												<Tag style={{ margin: '4px' }} color={TYPE_COLORS[workDay?.offColor]}>
													{extraRequest?.acronym}
												</Tag>
											</Tooltip>
									  ))
									: null}
							</>
						);
					} else {
						return null;
					}
				}
			});
		}

		cols.push({
			title: t('common.totalWageFine'),
			dataIndex: 'totalWageFine',
			width: '10rem',
			align: 'center',
			sorter: true,
			sticky: true
		});
		cols.push({
			title: t('admin.totalWorkDay'),
			dataIndex: 'totalWorkDays',
			width: '10rem',
			align: 'center',
			sorter: true,
			sticky: true
		});
		return cols;
	}, [t, filter?.date]);
}

export default useCreateCols;
