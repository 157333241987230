import {
	URL_EXPORT_ACCOUNTANT,
	URL_EXPORT_SAMPLE,
	URL_PIN_CODE,
	URL_SEND_EMAILS,
	URL_UPLOAD_ACCOUNTANT_EXCEL,
	URL_UPLOAD_CONFIRM
} from '../constant/common/api';
import axiosClient from './axiosClient';

export const accountantApi = {
	upload: (file, config) => {
		const formData = new FormData();
		formData.append('file', file);
		return axiosClient.post(URL_UPLOAD_ACCOUNTANT_EXCEL, formData, config);
	},
	confirmUpload: (filename, month) => {
		return axiosClient.post(`${URL_UPLOAD_CONFIRM}?date=${month}&fileName=${filename}`);
	},
	exportExcelMembers: (listUsername, date) => {
		return axiosClient.post(URL_EXPORT_ACCOUNTANT, listUsername, {
			responseType: 'arraybuffer',
			params: { date }
		});
	},
	downloadSample: () => {
		return axiosClient.post(URL_EXPORT_SAMPLE, null, {
			responseType: 'blob'
		});
	},
	sendEmailAcc: (listUsername, month) => {
		return axiosClient.post(`${URL_SEND_EMAILS}?date=${month}`, listUsername);
	},
	verifyCode: (domain, code) => {
		return axiosClient.post(`${URL_PIN_CODE}${domain}`, code, {
			headers: {
				Accept: 'application/json'
			}
		});
	},
	resetPin: (domain) => {
		return axiosClient.post(`${URL_PIN_CODE}${domain}`);
	}
};
