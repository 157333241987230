import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useDetailHistory() {
	const { t } = useTranslation();

	return useMemo(() => {
		const columns = [
			{
				title: t('admin.edit_time'),
				dataIndex: 'updateAt',
				sorter: true,
				width: '12%',
				align: 'center',
				render: time => <span className='workDate'>{time}</span>
			},

			{
				title: t('admin.update_by'),
				dataIndex: 'updateBy',
				width: '10%',
				align: 'center'
			},
			{
				title: t('admin.init_data'),
				dataIndex: 'originalValue',
				width: '20%',
				align: 'center'
			},
			{
				title: t('admin.status'),
				dataIndex: 'status',
				width: '6%',
				align: 'center'
			}
		];
		return columns;
	}, [t]);
}
