import React from 'react';

const TextSecurity = ({ number, isToggle }) => {
	let filteredNumber = number?.toLocaleString('vi-VN');

	if (filteredNumber) {
		filteredNumber = isToggle ? filteredNumber.replace(/\d/g, '*') : filteredNumber;
	} else {
		filteredNumber = '--';
	}

	return <span key={number}>{filteredNumber} VND</span>;
};

export default TextSecurity;
