import { Button, Checkbox, Form, Input, Radio } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, setRecord } from '../../../../app/reducers/formSlice';
import ETable from '../../../../components/ETable';
import FormItem from '../../../../components/FormItem';
import ModalForm from '../../../../components/Modal';
import FormModal from '../../../user/Request/components/FormModal/FormModal';
import { APPROVE_ADMIN, NEW_REQUEST_TAB_COLUMNS_ADMIN, ROLE } from '../../../../constant';
import '../../../../assets/scss/_layout.scss';
import { leaderApi } from '../../../../api/leaderApi';
import _ from 'lodash';

const { TextArea } = Input;

function NewRequestTabContent({ data, loading, mutate }) {
	const dispatch = useDispatch();
	const { roles, username } = useSelector(state => state.auth);
	const { id, record } = useSelector(state => state.form);
	const [idFormApprove, setIdFormApprove] = useState();
	const [form] = Form.useForm();
	const [formType, setFormType] = useState();
	const approveType = Form.useWatch('approveType', form);
	const checkRolesApprove = roles?.includes(ROLE.COSIC_ADMIN) || roles?.includes(ROLE.SUPER_ADMIN);
	const [selectedRequestsIds, setSelectedRequestsIds] = useState([]);

	const ApproveForm = () => {
		return (
			<>
				{username === record?.reviewer && !checkRolesApprove ? (
					<h2>{t('user.confirm_review')}</h2>
				) : (
					<h2>{t('common.approve')}</h2>
				)}
				<FormItem name='cease' label={t('common.request')}>
					{selectedRequestsIds.join(', ')}
				</FormItem>

				<FormItem name='approveType' label={t('admin.confirm')}>
					<Radio.Group>
						{APPROVE_ADMIN.map((x) => (
							<Radio value={x.value} key={x.value}>
								{x.title}
							</Radio>
						))}
					</Radio.Group>
				</FormItem>
				<FormItem name='comment' label={t('leader.new_request.comment')} required={approveType === 'REJECT'}>
					<TextArea rows={4} />
				</FormItem>
			</>
		);
	};



	useEffect(() => {
		if (formType === 'approveForm' && selectedRequestsIds.length > 0 && data) {
			const dataApprove = _.find(data?.data, item => item.id === selectedRequestsIds[0]);
			form.setFieldsValue({
				cease: `${dataApprove?.offTypeAcronym} - ${dataApprove?.offTypeName}`,
				approveType: APPROVE_ADMIN[0].value,
				id: idFormApprove,
				comment: dataApprove?.comment
			});
		}
	}, [formType, selectedRequestsIds, data]);

	const handleDetails = record => {
		setIdFormApprove(record.id);
		setFormType('editForm');
		dispatch(openModal(record.id));
	};

	const actionApproveClick = record => {
		setIdFormApprove(record.id);
		setFormType('approveForm');
		dispatch(openModal(record.id));
		dispatch(setRecord(record));
	};

	const openApproveFormModal = selectedIds => {

		if (selectedIds.length > 0) {
			setSelectedRequestsIds(selectedIds);
			setFormType('approveForm');
			dispatch(openModal());
		}
	};


	const handleCheckboxChange = (e, id) => {
		const selected = e.target.checked;
		setSelectedRequestsIds(prevSelectedIds =>
			selected ? [...prevSelectedIds, id] : prevSelectedIds.filter(selectedId => selectedId !== id)
		);
	};

	const columns = [
		{
			title: (
				<Checkbox
					onChange={e => {
						const selectedIds = e.target.checked ? data?.data.map(request => request.id) : [];
						setSelectedRequestsIds(selectedIds);
					}}
				/>
			),
			dataIndex: 'id',
			render: id => (
				<Checkbox
					onChange={e => handleCheckboxChange(e, id)}
					checked={selectedRequestsIds.includes(id)}
				/>
			)
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id'
		},

		...NEW_REQUEST_TAB_COLUMNS_ADMIN,
		{
			title: t('common.action'),
			align: 'center',
			dataIndex: 'action',
			render: (_, record) => {
				const roleReviewTome = record?.reviewer === username && !checkRolesApprove;

				return (
					<div className='actions'>
						<Button type='link' className='btn-orange' onClick={() => handleDetails(record)}>
							{t('common.detail')}
						</Button>
						{roleReviewTome ? (
							<Button type='link' className='btn-orange' onClick={() => actionApproveClick(record)}>
								{t('common.reviewCF')}
							</Button>
						) : (
							<Button
								type='link'
								className='btn-orange'
								disabled={!(checkRolesApprove || roles?.includes(ROLE.COSIC_LEADER))}
								onClick={() => openApproveFormModal([record.id])}>
								{t('common.approve')}
							</Button>
						)}
					</div>
				);
			}
		}
	];

	const onFinish = async (value) => {
		const action = form.getFieldValue('action');
		const payload = { approveType: value.approveType, comment: value.comment };

		if (((username === record?.reviewer && !checkRolesApprove) || action === 'review') && value.approveType !== 'REJECT') {
			payload.approveType = 'REVIEW';
		}

		if (selectedRequestsIds.length === 1) {
			// Call the API for a single request
			const id = selectedRequestsIds[0];
			await leaderApi.approveRequest(id, payload);
		} else if (selectedRequestsIds.length > 1) {
			// Call the API for multiple requests
			await leaderApi.approveMultipleRequest({ requestIds: selectedRequestsIds, request: payload });
		}

		mutate?.();
	};



	return (
		<>
			<ETable
				header={
					<Button
						onClick={() => openApproveFormModal(selectedRequestsIds)}
						disabled={selectedRequestsIds.length === 0}
					>
						Duyệt nhiều request
					</Button>
				}
				rowKey='id'
				columns={columns}
				dataSource={data?.data ?? []}
				pagination={{
					total: data ? data?.totalElements : null
				}}
				loading={loading}
			/>

			{formType === 'approveForm' ? (
				<ModalForm
					title={t('leader.new_request.confirm_request')}
					onSubmit={onFinish}
					form={form}
					isModified={true}
					successMessage={t('leader.new_request.approve_success')}
					isApprove={true}
					disabled={false}
					selectedRequests={selectedRequestsIds}
					moreButtons={
						checkRolesApprove ? (
							<Button
								onClick={() => {
									form.setFieldsValue({ action: 'review' });
									form.submit();
								}}
							>
								Đánh giá
							</Button>
						) : undefined
					}
				>
					<ApproveForm form={form} />
				</ModalForm>
			) : (
				<FormModal disabled={true} />
			)}
		</>
	);
}

export default NewRequestTabContent;
