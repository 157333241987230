import { Badge, Tag } from 'antd';
import { t } from 'i18next';
import NewRequestTabContent from '../pages/admin/Approve/component/NewRequestTabContent';
import ProcessedTabContent from '../pages/admin/Approve/component/ProcessedTabContent';
import i18n from '../translation/i18n';
import { ACCEPT, REJECT, TYPE_COLORS } from './common';
import { renderStatus } from '../utils';

export const TABS_DATA_ADMIN = (data, loading, countRequest, mutate, checkRolesApprove) => {
	return [
		{
			title: (
				<div className='tab-data'>
					{i18n.t('leader.new_request.new_request')}
					<Badge
						count={countRequest}
						style={{
							backgroundColor: '#E6F7FF',
							color: '#1890ff',
							marginLeft: 10
						}}
					/>
				</div>
			),
			content: <NewRequestTabContent data={data} loading={loading} mutate={mutate} checkRolesApprove={checkRolesApprove} />,
			key: 'new_request'
		},
		{
			title: <div className='tab-data'>{i18n.t('leader.approved.processed')}</div>,
			content: <ProcessedTabContent data={data} loading={loading} mutate={mutate} checkRolesApprove={checkRolesApprove} />,
			key: 'approved'
		}
	];
};

export const APPROVE_ADMIN = [
	{
		title: i18n.t('common.accept'),
		value: 'ACCEPT'
	},
	{
		title: i18n.t('common.refuse'),
		value: 'REJECT'
	}
];

export const NEW_REQUEST_TAB_COLUMNS_ADMIN = [
	{
		title: 'username',
		dataIndex: 'username',
		align: 'center'
	},
	{
		title: t('common.name'),
		dataIndex: 'member',
		align: 'center',
		sorter: true,
		render: (_, record) => record?.fullName,
		showSorterTooltip: {
			title: i18n.t('common.sort_tooltip')
		}
	},

	{
		title: t('user.daySendRequest'),
		dataIndex: 'createdAt',
		sorter: true,
		align: 'center',
		showSorterTooltip: {
			title: i18n.t('common.sort_tooltip')
		}
	},

	{
		title: t('common.request'),
		align: 'center',
		dataIndex: 'offTypeAcronym',
		sorter: true,
		render: (offTypeAcronym, { offTypeColor, offTypeName }) => {
			return <Tag style={{ whiteSpace: 'normal' }} color={TYPE_COLORS[offTypeColor]}>{`${offTypeAcronym} - ${offTypeName}`}</Tag>;
		}
	},

	{
		title: t('request.request_day'),
		align: 'center',
		// dataIndex: 'time',
		render: (_, record) => {
			const arrayStringMonth = record.monthAndYearForRequestCP && record?.monthAndYearForRequestCP?.split('/');
			return record.isHaflDay === true && record.isMorning === MORNING ? (
				<span className='time_Morning'>
					{t('leader.timeMorning', {
						val1: record.startDate,
						val2: record.endDate
					})}
				</span>
			) : record.isHaflDay === true && record.isMorning === AFTERNOON ? (
				<span className='time_afternoon'>
					{t('leader.timeAfternoon', {
						val1: record.startDate,
						val2: record.endDate
					})}
				</span>
			) : record.startDate && record?.endDate ? (
				<span className='time_AllDate'>
					{t('leader.timeAllDate', {
						val1: record.startDate,
						val2: record.endDate
					})}
				</span>
			) : record.offTypeAcronym === 'OT' ? (
				<span className='time_hoursDate'>
					{t('leader.timeHoursDate', {
						val1: record.time,
						val2: record.startDate
					})}
				</span>
			) : record?.startDate && record?.time ? (
				<span className='time_timeDate'>
					{t('leader.timeTimeDate', {
						val1: moment(record.time, FORMAT_HH_MM).format(FORMAT_HH_MM),
						val2: record.startDate
					})}
				</span>
			) : record.startDate && record?.time === null ? (
				<span className='time_date'>{t('leader.timeDate', { val1: record.startDate })}</span>
			) : record.monthAndYearForRequestCP ? (
				<span className='time_date'>{arrayStringMonth[1].concat('/', arrayStringMonth[2])}</span>
			) : (
				'-'
			);
		}
	},
	{
		title: t('request.request_time'),
		dataIndex: 'time',
		align: 'center',
		showSorterTooltip: {
			title: i18n.t('common.sort_tooltip')
		},
		render: (_, record) => {
			switch (record.offTypeAcronym) {
				case 'OT':
					return `${record.timeOT}`;
				case 'Ci':
					return `${record.timeCheck}`;
				case 'Cout':
					return `${record.timeCheck}`;
				case 'E':
					return `${record.timeCheck}`;
				case 'L':
					return `${record.timeCheck}`;
				default:
					return '-';
			}
		}
	},
	{
		title: t('common.reason'),
		align: 'center',
		dataIndex: 'reason'
	},
	{
		title: t('user.personApproved'),
		dataIndex: 'acceptedBy',
		align: 'center',
		sorter: true,
		render: (_, record) => {
			return `${record?.acceptedByFullName}`;
		}
	},
	{
		title: t('user.reviewer'),
		dataIndex: 'reviewer',
		align: 'center',
		sorter: true,
		render: (_, record) => {
			return record?.reviewerFullName ? `${record?.reviewerFullName}` : '-';
		}
	},
	{
		title: t('leader.approved.status'),
		dataIndex: 'status',
		align: 'center',
		render: renderStatus
	}
];

export const APPROVE_TAB_COLUMNS_ADMIN = [
	{
		title: 'username',
		dataIndex: 'username',
		align: 'center'
	},
	{
		title: t('common.name'),
		dataIndex: 'username',

		align: 'center',
		render: (_, record) => record?.fullName,
		sorter: true
	},
	{
		title: t('leader.new_request.day_send'),
		dataIndex: 'createdAt',
		align: 'center',

		sorter: true,
		showSorterTooltip: {
			title: i18n.t('common.sort_tooltip')
		}
	},

	{
		title: t('common.request'),
		dataIndex: 'offTypeAcronym',
		align: 'center',
		width: '16%',
		sorter: true,
		render: (offTypeAcronym, { offTypeColor, offTypeName }) => {
			return <Tag color={TYPE_COLORS[offTypeColor]}>{`${offTypeAcronym} - ${offTypeName}`}</Tag>;
		}
	},
	{
		title: t('user.day_time'),
		align: 'center',
		dataIndex: 'time',
		render: (_, record) => {
			return record.isHaflDay === true && record.isMorning === MORNING ? (
				<span className='time_Morning'>
					{t('leader.timeMorning', {
						val1: record.startDate,
						val2: record.endDate
					})}
				</span>
			) : record.isHaflDay === true && record.isMorning === AFTERNOON ? (
				<span className='time_afternoon'>
					{t('leader.timeAfternoon', {
						val1: record.startDate,
						val2: record.endDate
					})}
				</span>
			) : record.startDate && record?.endDate ? (
				<span className='time_AllDate'>
					{t('leader.timeAllDate', {
						val1: record.startDate,
						val2: record.endDate
					})}
				</span>
			) : record.offTypeAcronym === 'OT' ? (
				<span className='time_hoursDate'>
					{t('leader.timeHoursDate', {
						val1: record.time,
						val2: record.startDate
					})}
				</span>
			) : record?.startDate && record?.time ? (
				<span className='time_timeDate'>
					{t('leader.timeTimeDate', {
						val1: moment(record.time, FORMAT_HH_MM).format(HOUR_FORMAT),
						val2: record.startDate
					})}
				</span>
			) : record.startDate && record?.time === null ? (
				<span className='time_date'>{t('leader.timeDate', { val1: record.startDate })}</span>
			) : null;
		}
	},
	{
		title: t('admin.last-version'),
		dataIndex: 'updatedAt',
		sorter: true,
		width: '12%',
		align: 'center'
	},
	{
		title: t('request.request_time'),
		dataIndex: 'createdAt',

		align: 'center',
		showSorterTooltip: {
			title: i18n.t('common.sort_tooltip')
		},
		render: (_, record) => {
			switch (record.offTypeAcronym) {
				case 'OT':
					return `${record.timeOT}`;
				case 'Ci':
					return `${record.timeCheck}`;
				case 'Cout':
					return `${record.timeCheck}`;
				case 'E':
					return `${record.timeCheck}`;
				case 'L':
					return `${record.timeCheck}`;
				default:
					return '-';
			}
		}
	},
	{
		title: t('common.reason'),
		dataIndex: 'reason',
		align: 'center',
		width: '15%'
	},
	{
		title: t('leader.approved.status'),
		dataIndex: 'status',
		align: 'center',
		render: renderStatus
	},
	{
		title: t('user.personApproved'),
		dataIndex: 'acceptedBy',
		align: 'center',

		sorter: true,
		render: (_, record) => {
			return `${record?.acceptedByFullName}`;
		}
	}
];
