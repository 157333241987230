export function exportFile(data, fileName, fileType) {
	const url = window.URL.createObjectURL(new Blob([data], { type: fileType }));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
}

export const exportExcel = (data, fileName) =>
	exportFile(data, `${fileName}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
