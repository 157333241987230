import React, { useEffect } from 'react';
import DownIcon from '../icons/DownIcon';
import moment from 'moment';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { FORMAT_MONTH } from '../../constant';
import { useFilter } from '../../common/hooks';
import { useLocation } from 'react-router-dom';

export default function MonthPicker(props) {
	const { t } = useTranslation();
	const { filter, setFilter } = useFilter();
	const { pathname } = useLocation();
	const customFormat = value => `${t('time_keeping.month')}: ${value.format(FORMAT_MONTH)}`;
	const momentNow = moment(moment(Date.now()).format(FORMAT_MONTH), FORMAT_MONTH);

	useEffect(() => {
		if (!filter?.date) {
			setFilter({
				...filter,
				date: momentNow.format(FORMAT_MONTH)
			});
		}
		// eslint-disable-next-line
	}, [pathname, filter]);

	return (
		<DatePicker
			picker='month'
			suffixIcon={<DownIcon />}
			allowClear={false}
			size='middle'
			format={customFormat}
			value={filter?.date ? moment(filter?.date, FORMAT_MONTH) : momentNow}
			defaultValue={momentNow}
			onChange={value => {
				setFilter({
					...filter,
					date: value ? moment(value).format(FORMAT_MONTH) : momentNow.format(FORMAT_MONTH)
				});
			}}
			{...props}
		/>
	);
}
