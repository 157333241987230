import {Button, Tag} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {openModal, setOpenModify} from '../../../app/reducers/formSlice';

import LongText from '../../../components/LongText/LongText';
import {MAX_DISPLAY_LEN, TYPE_COLORS} from '../../../constant/common';
import {formatNum} from '../../../utils';

export default function useCreateColumns() {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const MaxText = text => <LongText max={MAX_DISPLAY_LEN}>{text}</LongText>;
	return useMemo(() => {
		const columns = [
			{
				title: t('common.workDay'),
				dataIndex: 'dateAt',
				align: 'center',
				sorter: true,
				fixed: true
			},
			{
				title: t('common.checkIn'),
				align: 'center',
				dataIndex: 'checkInTime'
			},
			{
				title: t('common.checkOut'),
				align: 'center',
				dataIndex: 'checkOutTime'
			},
			{
				title: t('common.ot'),
				align: 'center',
				dataIndex: 'otHour',
				render: formatNum
			},
			{
				title: t('admin.lateTime'),
				align: 'center',
				dataIndex: 'lateTime',
				render: formatNum
			},
			{
				title: t('admin.earlyTime'),
				align: 'center',
				dataIndex: 'earlyTime',
				render: formatNum
			},
			{
				title: t('admin.disconnectTime'),
				align: 'center',
				dataIndex: 'disconnectTime'
			},
			{
				title: t('admin.workHour'),
				align: 'center',
				dataIndex: 'workTime',
				render: formatNum
			},
			{
				title: t('admin.cong'),
				align: 'center',
				dataIndex: 'wage',
				render: formatNum
			},
			{
				title: t('common.timeLate'),
				dataIndex: 'timeGoLateWentEarly',
				render: formatNum,
				align: 'center'
			},
			{
				title: t('common.request'),
				align: 'center',
				dataIndex: 'request',
				render: (value, { offColor }) => {
					return <Tag color={TYPE_COLORS[offColor]}>{value}</Tag>;
				}
			},
			{
				title: t('common.reason'),
				align: 'center',
				dataIndex: 'reason',
				render: MaxText
			},
			{
				title: t('common.action'),
				align: 'center',
				dataIndex: 'id',
				render: id => (
					<Button
						type='link'
						className='color-primary'
						onClick={() => {
							dispatch(setOpenModify(true));
							dispatch(openModal(id));
						}}>
						{t('common.modify')}
					</Button>
				)
			}
		];
		return columns;
	}, [t, dispatch]);
}
