import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch, useFilter } from '../../../common/hooks';
import ETable from '../../../components/ETable';
import { ADMIN_LOG_TIME_MEMBER, URL_ADMIN_EXPORT_TIMEKEEPING } from '../../../constant/common/api';
import useCreateCols from './useCreateCols';
import { getWorkingDay } from '../../../utils/dateTime';
import useExportExcel from '../useExportExcel';

export default function TimeKeepingTable({ isSync = true }) {
	const { filter } = useFilter();
	const { t } = useTranslation();
	const columns = useCreateCols();
	const { data, loading } = useFetch({
		url: ADMIN_LOG_TIME_MEMBER
	});
	const handleExport = useExportExcel(URL_ADMIN_EXPORT_TIMEKEEPING, filter?.date, t('export.timekeeping_table'));

	return (
		<ETable
			header={
				<div style={{ display: 'flex' }}>
					<h3>{t('admin.totalWorkInMonth')}</h3>
					<h3 style={{ paddingLeft: '15px' }}>{getWorkingDay(filter?.date)}</h3>
				</div>
			}
			dataSource={data?.data}
			pagination={{
				total: data?.totalElements
			}}
			columns={columns}
			loading={loading}
			rowKey='username'
			handleExport={handleExport}
			isSync={isSync}
		/>
	);
}
