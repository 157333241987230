import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './app/store';
import './index.css';
import { Root } from './root';
import i18n from './translation/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FetchProvider } from './common/hooks/useFetch';
import moment from 'moment';
import vi from 'moment/locale/vi';

moment.locale('vi', vi);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
});

ConfigProvider.config({
	theme: {
		primaryColor: '#f18805'
	}
});
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<ConfigProvider>
				<BrowserRouter>
					<QueryClientProvider client={queryClient}>
						<FetchProvider>
							<Root />
						</FetchProvider>
					</QueryClientProvider>
				</BrowserRouter>
			</ConfigProvider>
		</I18nextProvider>
	</Provider>
);
