import React from 'react';
import { useFetch } from '../../../common/hooks';
import ETable from '../../../components/ETable';
import { ADMIN_HISTORY } from '../../../constant/common/api';
import useCreateColumns from './useColumns';
import FormModal from '../../user/Request/components/FormModal/FormModal';
export default function EditedHistory() {
	const { data, loading, mutate } = useFetch({
		url: ADMIN_HISTORY,
		requiredParams: ['date']
	});
	const column = useCreateColumns(mutate);
	// const { data } = useFetch({
	// 	url: ADMIN_HISTORY,
	// 	defaultParams: { id },
	// 	withQuery: false
	// 	// requiredParams: ['id']
	// });
	return (
		<div className='Table_EditHistory'>
			<ETable
				loading={loading}
				columns={column}
				dataSource={data?.data}
				rowKey='note'
				pagination={{
					total: data?.totalElements
				}}
			/>
			<FormModal isEditHistories={true} width={800} data={data} />
		</div>
	);
}
