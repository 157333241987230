import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import '../../../assets/scss/_layout.scss';
import { useFetch, useFilter } from '../../../common/hooks';
import TabsCustomed from '../../../components/Tabs';
import { CHECK_STATUS_APPROVE, ROLE, STATUS_COMPLETE_REJECT, STATUS_PENDING, TABS_DATA_ADMIN, URL } from '../../../constant';
export default function ApproveAdmin() {
	const { filter, setFilter } = useFilter();
	const { roles } = useSelector(state => state.auth);

	const [status, setStatus] = useState(STATUS_PENDING);

	const url = URL.LEADER_REQUEST_URL;
	const { data, loading, mutate } = useFetch({ url, defaultParams: { statusSet: status } });

	const [countRequest, setCountRequest] = useState(data?.totalElements);
	const location = useLocation();
	const onSearch = value => {
		console.log('value :', value);
		setFilter({
			...filter,
			keyword: value
		});
	};

	const onChangeTabs = key => {
		setFilter({
			...filter,
			activeTab: key
		});
	};
	useEffect(() => {
		if (status === STATUS_PENDING && data?.totalElements) {
			setCountRequest(data.totalElements);
		}
	}, [status, data]);
	return (
		<TabsCustomed
			data={TABS_DATA_ADMIN(data, loading, status === STATUS_PENDING ? data?.totalElements : countRequest, mutate)}
			propsFilter={{
				onSearch
			}}
			propsTab={{
				onChange: onChangeTabs,
				defaultActiveKey: TABS_DATA_ADMIN(data, loading)[0].key,
				activeKey: location.search.activeKey
			}}
			ChangeStatus={key => (key === CHECK_STATUS_APPROVE ? setStatus(STATUS_COMPLETE_REJECT) : setStatus(STATUS_PENDING))}
		/>
	);
}
