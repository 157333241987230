/* eslint-disable no-debugger */
import { getToken, setToken } from '../common/cookies/Cookie';
import { SESSION_TOKEN_KEY } from '../constant';
import { useQuery } from '@tanstack/react-query';
import { authApi } from '../api/authApi';
import { useEffect } from 'react';
import { gotoLogin } from '../utils/auth';
import { useFilter } from '../common/hooks';

export const useGetToken = () => {
	const { filter, setFilter } = useFilter();
	const sessionToken = filter?.[SESSION_TOKEN_KEY];

	useEffect(() => {
		if (!(sessionToken || getToken())) {
			gotoLogin();
		}
	}, []);

	const { data, isSuccess } = useQuery({
		queryKey: ['access-token', sessionToken],
		queryFn: () => authApi.getAccessToken(sessionToken).then(res => res.data?.token),
		enabled: !!sessionToken
	});

	useEffect(() => {
		if (isSuccess) {
			setToken(data);
			setFilter({
				...filter,
				[SESSION_TOKEN_KEY]: undefined
			});
		}
	}, [isSuccess]);

	return data || getToken();
};
