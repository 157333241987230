import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useCreateColumns() {
	const { t } = useTranslation();
	return useMemo(() => {
		const columns = [
			{
				title: t('common.name'),
				align: 'center',
				dataIndex: 'fullName',
				sorter: true
			},
			{
				title: t('admin.totalWorkDay'),
				dataIndex: 'workDays',
				align: 'center',
				sorter: true
			},
			{
				title: t('admin.vacationWithSalaryInMonth'),
				dataIndex: 'offPaid',
				align: 'center',
				sorter: true
			},
			{
				title: t('admin.vacationWithoutSalary'),
				dataIndex: 'roDays',
				align: 'center',
				sorter: true
			},
			{
				title: t('common.ot'),
				dataIndex: 'overTimes',
				align: 'center',
				sorter: true
			},
			{
				title: t('common.totalWageFine'),
				dataIndex: 'totalWageFine',
				align: 'center',
				sorter: true
			},
			{
				title: t('admin.totalVacationWithSalaryInYear'),
				dataIndex: 'totalPDays',
				align: 'center',
				sorter: true
			},
			{
				title: t('admin.totalVacationWithSalaryInDay'),
				dataIndex: 'pdaysUsed',
				align: 'center',
				sorter: true
			},
			{
				title: t('admin.remainingVacationWithSalaryInDay'),
				dataIndex: 'pdaysRemain',
				align: 'center',
				sorter: true
			}
		];
		return columns;
	}, [t]);
}
