import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilter } from '../../common/hooks';
import { DATE_FORMAT } from '../../constant';
const RangePick = ({ keyName, format, onChange, ...props }) => {
	const { t } = useTranslation();
	const { filter, setFilter } = useFilter();
	const [dPick, setDPick] = useState();
	useEffect(() => {
		if (!dPick) {
			setDPick([moment(filter?.dateStart, DATE_FORMAT), moment(filter?.dateEnd, DATE_FORMAT)]);
		}
	}, []);
	const handleFilterTime = values => {
		const dateStart = values ? moment(values?.[0]).format(DATE_FORMAT) : undefined;
		const dateEnd = values ? moment(values?.[1]).format(DATE_FORMAT) : undefined;
		setFilter({
			...filter,
			dateStart,
			dateEnd
		});
	};
	return <DatePicker.RangePicker format={format} defaultValue={dPick} onChange={values => handleFilterTime(values)} {...props} />;
};

export default RangePick;
