import { Button, message, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../../app/reducers/formSlice';
import { accountantApi } from '../../../api/acountantApi';

const ModalInputPin = ({ mutate }) => {
	const [pin, setPin] = useState('');
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { visible, id } = useSelector(state => state.form);
	const handleOk = async () => {
		try {
			if (pin.length === 6) {
				await accountantApi.verifyCode('verify', pin);
				dispatch(closeModal());
				message.success('Xác thực thành công!');
				mutate?.();
			}
		} catch (e) {
			if (e.response.status === 428) {
				dispatch(openModal('createPin'));
			}
			message.error(e.response.data.message);
		}
	};

	const handleCancel = () => {
		dispatch(closeModal());
	};

	const handleReset = async () => {
		setLoading(true);
		const response = await accountantApi.resetPin('reset');
		message.success(response.data.message);
		setLoading(false);
	};
	return (
		<Modal
			centered
			width={400}
			title='Nhập mã pin'
			open={visible}
			onOk={handleOk}
			onCancel={handleCancel}
			icon
			okText='Xác Thực'
			okType='primary'
			footer={[
				<Button className='reset-pin' key='reset' onClick={handleReset} loading={loading}>
					Đặt lại PIN
				</Button>,
				<Button key='cancel' onClick={handleCancel}>
					Hủy bỏ
				</Button>,
				<Button key='submit' type='primary' onClick={handleOk}>
					Xác Thực
				</Button>
			]}>
			<div className='modal-input'>
				<p>* Bạn cần nhập mã pin để dùng trang “Phiếu lương nhân viên”, hãy nhập mã pin!</p>
				<ReactCodeInput type='password' value={pin} fields={6} onChange={value => setPin(value)} />
			</div>
		</Modal>
	);
};

export default ModalInputPin;
