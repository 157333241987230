import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form } from 'antd';
import './EyeMenu.scss';

const { useForm, List, Item } = Form;

const EyeMenu = ({
	onOk = cols => {
		console.log(cols);
	},
	cols
}) => {
	const [form] = useForm();
	const { t } = useTranslation();

	useEffect(() => {
		form.setFieldsValue({
			cols
		});
	}, [form, cols]);

	return (
		<Form
			form={form}
			className='eye-menu'
			onFinish={values => {
				onOk(values.cols);
			}}
		>
			<List name='cols'>
				{fields => {
					return fields.map(({ key, name, ...restField }) => (
						<div key={key} className='eye-menu__row'>
							<Item {...restField} noStyle valuePropName='checked' name={[name, 'show']}>
								<Checkbox id={`col-show-${name}`} />
							</Item>
							<Item {...restField} noStyle shouldUpdate={(prev, curr) => prev.cols[name].title !== curr.cols[name].title}>
								{({ getFieldValue }) => (
									<label htmlFor={`col-show-${name}`} className='eye-menu__label'>
										{getFieldValue(['cols', name, 'title'])}
									</label>
								)}
							</Item>
						</div>
					));
				}}
			</List>
			<Button htmlType='submit' type='primary'>
				{t('button.ok')}
			</Button>
		</Form>
	);
};

export default memo(EyeMenu);
