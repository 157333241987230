import {Button, message} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {userRequestApi} from '../../../../api/userRequestApi';
import {openModal} from '../../../../app/reducers/formSlice';
import ETable from '../../../../components/ETable';
import showModalConfirm from '../../../../components/notifications/showModalConfirm';
import {APPROVE_TAB_COLUMNS_ADMIN} from '../../../../constant/admin';
import FormModal from '../../../user/Request/components/FormModal/FormModal';

export default function ApproveTabContent({data, loading, mutate, checkRolesApprove}) {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const handleClick = record => {
		dispatch(openModal(record.id));
	};
	const columns = [
		...APPROVE_TAB_COLUMNS_ADMIN,
		{
			title: t('common.action'),
			dataIndex: 'action',

			align: 'center',
			render: (_, record) => {
				return (
					<>
						<Button type='link' className='btn-orange' onClick={() => handleClick(record)}>
							{t('common.detail')}
						</Button>
						<Button
							type='link'
							className='btn-orange'
							onClick={() => {
								showModalConfirm({
									title: t('message.cancelRequest'),
									onOk: async () => {
										try {
											await userRequestApi.cancelRequest(record?.id);
											message.success(t('message.cancelSuccess'), {
												action: t('common.delete')
											});
											mutate?.();
										} catch (error) {
											message.error(error.response.data.message);
										}
									}
								});
							}}
							disabled={checkRolesApprove}>
							{t('leader.approved.cancel')}
						</Button>
					</>
				);
			}
		}
	];
	return (
		<>
			<ETable
				rowkey='id'
				columns={columns}
				dataSource={data ? data.data : []}
				pagination={{
					total: data ? data?.totalElements : null
				}}
				loading={loading}
			/>
			<FormModal disabled={true} />
		</>
	);
}
