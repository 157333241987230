import { message, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountantApi } from '../../../api/acountantApi';
import { closeModal } from '../../../app/reducers/formSlice';

import { useFilter } from '../../../common/hooks';
import { exportExcel } from '../../../utils/exportFile';
import { showErrMsg } from '../../../utils';
import DragUploadFile from './DragUploadFile';

const ModalLoadFormData = () => {
	const { filter } = useFilter();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState();
	const [filename, setFilename] = useState('');

	const { visible } = useSelector(state => state.form);

	const handleSubmit = useCallback(async () => {
		setLoading(true);
		try {
			await accountantApi.confirmUpload(filename, filter?.date);
			dispatch(closeModal());
			message.success('Tải file excel thành công.');
		} catch (error) {
			showErrMsg(error);
		} finally {
			setLoading(false);
		}
	}, [filename, filter?.date, dispatch]);

	const onClose = useCallback(() => {
		dispatch(closeModal());
	}, [dispatch]);

	const downLoadSample = useCallback(async () => {
		try {
			const response = await accountantApi.downloadSample();
			exportExcel(response.data, 'BANG_LUONG_MAU');
		} catch (error) {
			console.error('Lỗi khi tải xuống tệp tin', error);
		}
	}, []);

	return (
		<Modal
			width={600}
			title='Tải lên file excel bảng lương '
			open={visible}
			onOk={handleSubmit}
			confirmLoading={loading}
			okButtonProps={{
				disabled: !filename
			}}
			onCancel={onClose}>
			<p>
				* Các dữ liệu trong tệp excel phải được chuẩn hóa theo mẫu sau:{' '}
				<a onClick={downLoadSample} href='#'>
					Tải tệp excel mẫu
				</a>{' '}
			</p>
			<DragUploadFile onSuccess={setFilename} />
		</Modal>
	);
};

export default ModalLoadFormData;
