import { Button, Result, Space } from 'antd';

export const ErrorPage = () => {
	return (
		<div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
			<Result
				status='404'
				title='404'
				subTitle='Xin lỗi, trang không tồn tại.'
				extra={
					<Button type='primary' onClick={() => (window.location.href = '/')}>
						Back Home
					</Button>
				}
			/>
		</div>
	);
};
