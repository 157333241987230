import React from 'react';
function StatisticCard({ title, count, image }) {
	return (
		<div className='statistic__card'>
			<div className='statistic__card__title'>
				<h3 className='statistic__card__title--text'>{title} :</h3>
				<h2 className='statistic__card__title--count'>{count}</h2>
			</div>
			<div className='statistic__card__img'>
				<img src={image} alt='statistic card' />
			</div>
		</div>
	);
}

export default StatisticCard;
