import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosClient from '../../../../api/axiosClient';
import FormItem from '../../../../components/FormItem';
import ModalForm from '../../../../components/Modal';
import { ADMIN_LOG_TIME, ADMIN_WAGE_CHANGE } from '../../../../constant/common/api';
import { showErrMsg } from '../../../../utils';
const { TextArea } = Input;

function UpdateForm({ mutate }) {
	const { t } = useTranslation('translation', {
		keyPrefix: 'common'
	});
	const { username } = useParams();
	const [userName, setUserName] = useState('');
	const [form] = Form.useForm();
	const { id, visible } = useSelector(state => state.form);
	useEffect(() => {
		const initValue = async () => {
			try {
				const fields = [];
				const { data } = await axiosClient.get(`${ADMIN_LOG_TIME}/${id}`);
				for (const key in data) {
					if (Object.hasOwnProperty.call(data, key)) {
						const element = data[key];
						if (element !== null) {
							let value = element;
							if (key === 'fullname') {
								value = `${element}`;
							}
							if (key === 'username') {
								setUserName(element);
							}
							fields.push({
								name: key,
								value
							});
						}
					}
				}
				form.setFields(fields);
			} catch (error) {
				showErrMsg(error);
			}
		};
		visible && initValue();
	}, [id, username, visible]);

	return (
		<ModalForm
			layout='horizontal'
			labelCol={{
				span: 6
			}}
			wrapperCol={{
				span: 18
			}}
			onSubmit={async values => {
				const newValues = Object.assign({}, values, {
					date: values.dateAt.replaceAll('/', '-'),
					wage: values.wage ? parseFloat(values.wage) : '',
					username: userName
				});
				delete newValues.dateAt;
				delete newValues.fullname;
				await axiosClient.post(`${ADMIN_WAGE_CHANGE}`, newValues);
				mutate(newValues);
			}}
			isAdd={false}
			form={form}
			title={t('modify')}
		>
			<FormItem label={t('name')} name='fullname'>
				<Input disabled />
			</FormItem>
			<FormItem label={t('day')} name='dateAt'>
				<Input disabled />
			</FormItem>
			<FormItem name='wage' label={t('wage')}>
				<Input type='number' />
			</FormItem>
			<FormItem name='comment' label={t('comment')} required>
				<TextArea rows={3} />
			</FormItem>
		</ModalForm>
	);
}

export default UpdateForm;
