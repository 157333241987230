import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountantApi } from '../../../api/acountantApi';
import { closeModal } from '../../../app/reducers/formSlice';
import { useFilter } from '../../../common/hooks';
import ETable from '../../../components/ETable';

const column = [
	{ title: 'Nhân viên', align: 'center', dataIndex: 'fullName' },
	{ title: 'username', align: 'center', dataIndex: 'username' }
];

const ModalSendsEmail = ({ dataSource }) => {
	const dispatch = useDispatch();
	const { filter } = useFilter();
	const [row, setRow] = useState([]);
	const [loading, setLoading] = useState();
	const { visible } = useSelector(state => state.form);

	const onClose = () => {
		dispatch(closeModal());
	};
	const handleOk = async () => {
		setLoading(true);
		try {
			await accountantApi.sendEmailAcc(row, filter?.date);
			setLoading(false);
			message.success('Gửi mail thành công.');
			dispatch(closeModal());
		} catch (error) {
			setLoading(false);
			message.error(error.response.data.message);
		}
	};

	return (
		<>
			<Modal confirmLoading={loading} open={visible} onCancel={onClose} title='Gửi Bảng lương' onOk={handleOk}>
				<ETable
					rowKey='username'
					rowSelection={{
						selectedRowKeys: row,
						onChange: setRow
					}}
					isShowUtilBar={false}
					scroll={{ y: '60vh', x: '100%' }}
					dataSource={dataSource}
					columns={column}
				/>
			</Modal>
		</>
	);
};

export default ModalSendsEmail;
