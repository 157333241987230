import { DatePicker, Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../../../../components/FormItem';
import { DATE_FORMAT, TODAY } from '../../../../../../constant';

const { RangePicker } = DatePicker;

export default function RemoteForm({ request }) {
	const { t } = useTranslation();
	const form = Form.useFormInstance();
	useEffect(() => {
		form.setFields([
			{
				name: 'rangeDate',
				value: [TODAY, TODAY]
			}
		]);
	}, [request]);
	return (
		<>
			<FormItem name='rangeDate' label={t('common.day')} rules={[{ required: true, message: t('message.exactlyDay') }]}>
				<RangePicker format={DATE_FORMAT} />
			</FormItem>
		</>
	);
}
