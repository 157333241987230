import { ArrowDownOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { statusAccountant } from '../../constant';
import TextSecurity from './components/TextSecurity';

export const createAccountantColumns = isToggle => {
	const renderSalary = item => {
		return <TextSecurity number={item} isToggle={isToggle} />;
	};

	return [
		{ title: 'STT', align: 'center', width: 60, render: (_, _record, index) => index + 1 },
		{ title: 'Nhân viên', align: 'center', dataIndex: 'fullName', sorter: true },
		{ title: 'username', align: 'center', dataIndex: 'username', sorter: true },
		{
			title: 'Tiền lương hợp đồng',
			dataIndex: 'income',
			align: 'center',
			render: renderSalary
		},
		{
			title: 'Tiền lương cơ bản',
			dataIndex: 'base',
			align: 'center',
			render: renderSalary
		},
		{ title: 'Số lượng người phụ thuộc', align: 'center', dataIndex: 'dependent' },
		{
			title: 'Tiền thưởng',
			dataIndex: 'reward',
			align: 'center',
			render: renderSalary
		},
		{
			title: 'Các khoản trừ',
			align: 'center',
			dataIndex: 'deduction',
			render: renderSalary
		},
		{
			title: 'Tổng lương',
			align: 'center',
			dataIndex: 'totalSalary',
			render: renderSalary
		},
		{
			title: 'Trạng thái',
			align: 'center',
			dataIndex: 'status',
			render: item => {
				const status = statusAccountant.find(status => {
					return status.value === item;
				});
				return <Tag color={status?.color}>{status?.value}</Tag>;
			}
		},
		{
			title: 'Cập nhật gần nhất',
			align: 'center',
			dataIndex: 'updateRecordAt'
		},
		{
			title: 'Hành động',
			align: 'center',
			render: record => {
				return (
					<a href={record?.excelUrl}>
						<ArrowDownOutlined style={{ fontSize: 20 }} />
					</a>
				);
			}
		}
	];
};
