import { Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilter } from '../../common/hooks';
export default function Filter({ onSearch, ...props }) {
	const { t } = useTranslation();
	const { filter, setFilter } = useFilter();
	const [searchValue, setSearchValue] = useState();
	function handleSearchInputChange(event) {
		setSearchValue(event.target.value);
	}

	const handleSubmit = () => {
		if (onSearch) {
			onSearch();
		} else {
			setFilter({
				...filter,
				keyword: searchValue
			});
		}
	};

	return (
		<Input.Search
			placeholder={t('filter.search')}
			value={searchValue}
			onChange={handleSearchInputChange}
			onSearch={handleSubmit}
			enterButton
			{...props}
		/>
	);
}
