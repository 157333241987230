import {DatePicker, Form, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FORMAT_MONTH} from '../../../../../../constant';

const {Option} = Select;

const ReparationForm = ({request, form}) => {
	const {t} = useTranslation();
	const [arrayNumber, setArrayNumber] = useState();
	const [notification, setNotification] = useState();
	const {pdays} = useSelector(state => state.common.pdays);
	const {notify} = useSelector(state => state.common.pdays);
	let loadPday = 1;
	if (notify === "Bạn không thể vay phép") {
		loadPday = 0;
	}
	useEffect(() => {
		const jump = 0.5;
		const array = [];
		if (pdays && pdays === 0) {
			setNotification('Bạn đã hết ngày nghỉ phép!');
		} else {
			for (let i = 0.5; i <= pdays + loadPday; i += jump) {
				array.push(i);
			}
			setArrayNumber(array);
		}
	}, []);

	return notification && !(form.getFieldValue('convertPdaysToWageFine') || form.getFieldValue('monthAndYearForRequestCP')) ? (
		<Form.Item noStyle>
			<p style={{ fontSize: '16px', color: 'red' }}>{notification}</p>
		</Form.Item>
	) : (
		<>
			<Form.Item
				name='convertPdaysToWageFine'
				label={t('common.compensatoryLeaveHours')}
				rules={[{ required: true, message: t('message.compensatoryLeaveHours') }]}>
				<Select placeholder={t('common.compensatoryLeaveHours')}>
					{arrayNumber &&
						arrayNumber.map(number => {
							return <Option key={number}>{number}</Option>;
						})}
				</Select>
			</Form.Item>
			<Form.Item
				name={'monthAndYearForRequestCP'}
				label={t('common.monthAndYearForRequestCP')}
				rules={[{ required: true, message: t('message.month') }]}>
				<DatePicker picker='month' format={FORMAT_MONTH} placeholder='chọn Tháng' />
			</Form.Item>
		</>
	);
};

export default ReparationForm;
