import React, { useCallback } from 'react';
import axiosClient from '../../api/axiosClient';
import { exportExcel } from '../../utils/exportFile';
import moment from 'moment/moment';
import { FORMAT_MONTH } from '../../constant';

function useExportExcel(url, month, fileName) {
	return useCallback(async () => {
		const { data } = await axiosClient.get(url, {
			params: { date: month },
			responseType: 'arraybuffer'
		});
		exportExcel(data, fileName + moment(month, FORMAT_MONTH).format('MMMM_YYYY').replace(' ', '_'));
	}, [url, month, fileName]);
}

export default useExportExcel;
