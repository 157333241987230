import { Editor } from '@tinymce/tinymce-react';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mutate } from 'swr';
import { userRequestApi } from '../../../api/userRequestApi';
import { useFetch } from '../../../common/hooks';
import { displayReview } from '../../../constant';
import { SYSTEM_CONFIG } from '../../../constant/common/api';
import { tinyPlugins, tinyToolbar } from './constants';
import './regulations.scss';

const Wysiwyg = () => {
	const { t } = useTranslation();

	const data = useFetch({ url: `${SYSTEM_CONFIG}` });
	const dataWysiwyg = data?.data;

	const [wysiwyg, setWysiwyg] = useState(null);
	const [isDisable, setIsDisable] = useState(true);

	const handleDisableWysiwyg = () => {
		setIsDisable(false);
	};

	useEffect(() => {
		setWysiwyg(dataWysiwyg);
	}, [dataWysiwyg]);

	const handleEditorChange = content => {
		setWysiwyg(content);
	};
	const { roles } = useSelector(state => state.auth);

	const handleSubmitWysiwyg = async () => {
		try {
			const wysiwygData = {
				value: wysiwyg
			};
			await userRequestApi.update_regulations(wysiwygData);
			setIsDisable(true);
			mutate();
			message.success(t('message.updateWysiwygSuccess'));
		} catch (e) {
			message.error(t('message.updateWysiwygError'));
		}
	};

	return (
		<div className='wysiwyg'>
			{_.intersection(roles, displayReview).length > 0 && (
				<div className='wysiwyg-edit'>
					<Editor
						disabled={isDisable}
						apiKey={process.env.REACT_APP_API_KEY_TINYMCE}
						init={{
							height: 720,
							selector: 'textarea',
							plugins: tinyPlugins,
							toolbar: tinyToolbar
						}}
						value={wysiwyg}
						onEditorChange={handleEditorChange}
					/>
					<div className='wysiwyg-btn'>
						{isDisable && <button onClick={handleDisableWysiwyg}>{t('common.modify')}</button>}
						{!isDisable && <button onClick={handleSubmitWysiwyg}>{t('common.update')}</button>}
					</div>
					<hr />
					<hr />
					<p className='wysiwyg-preview'>{t('common.preview')}</p>
				</div>
			)}

			<div className='wysiwyg-content'>{wysiwyg && ReactHtmlParser(wysiwyg)}</div>
		</div>
	);
};

export default Wysiwyg;
