import Cookies from 'js-cookie';
import { SESSION_TOKEN_KEY } from '../../constant';

const USER_KEY = 'username';

export function getToken() {
	return Cookies.get(SESSION_TOKEN_KEY);
}

export function setToken(token) {
	return Cookies.set(SESSION_TOKEN_KEY, token, { expires: 365 });
}

export function removeToken() {
	return Cookies.remove(SESSION_TOKEN_KEY);
}

export function setUser(user) {
	return Cookies.set(USER_KEY, user, { expires: 365 });
}

export function getUser() {
	return Cookies.get(USER_KEY);
}

export function removeUser() {
	return Cookies.remove(USER_KEY);
}

export function clearCookie() {
	return Object.keys(Cookies.get()).forEach(tokenName => Cookies.remove(tokenName));
}
