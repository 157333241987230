import { Link, matchPath } from 'react-router-dom';

export const calculateMenuUI = (resMenus, userRoutesFlatten, lang = 'vi', hasParent = false) => {
	const results = [];
	resMenus.forEach(menu => {
		const { menuCode, children, label } = menu;

		const langLabel = label?.[lang];
		const route = userRoutesFlatten.find(route => route.key === menuCode && route.path);
		if (route) {
			const { icon, path } = route;
			const common = {
				...(hasParent ? {} : { icon }),
				key: menuCode
			};
			if (children && children.length > 0) {
				results.push({
					label: langLabel,
					...common,
					children: calculateMenuUI(children, userRoutesFlatten, lang, true)
				});
			} else {
				results.push({
					label: <Link to={path || ''}>{langLabel}</Link>,
					...common
				});
			}
		}
	});
	return results;
};

export const calculateKeyByPathname = (pathname, routes) => {
	const route = routes.find(route => matchPath(route.path || '', pathname) && route.element);

	return route?.key || '';
};
