import { createSlice } from '@reduxjs/toolkit';

const commonReducer = createSlice({
	name: 'common',
	initialState: {
		reload: false,
		visibleModal: false,
		pdays: undefined
	},
	reducers: {
		setReload(state) {
			state.reload = !state.reload;
		},
		setVisibleModal(state) {
			state.visibleModal = !state.visibleModal;
		},
		setPdays(state, { payload }) {
			state.pdays = payload;
		}
	}
});
const { reducer, actions } = commonReducer;
export const { setReload, setVisibleModal, setPdays } = actions;
export default reducer;
