import { Tag } from 'antd';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { userRequestApi } from '../../../../api/userRequestApi';
import TableDetails from '../../../../components/TableDetail/TableDetails';
import { PENDING, TYPE_COLORS } from '../../../../constant/common';
import { t } from 'i18next';
import { renderStatus } from '../../../../utils';

export default function useColumn(mutate) {
	const dispatch = useDispatch();

	return useMemo(() => {
		const columns = [
			{
				title: t('user.daySendRequest'),
				width: 125,
				dataIndex: 'createdAt',
				sorter: true,
				align: 'center'
			},
			{
				title: t('admin.id'),
				dataIndex: 'id',
				width: 75,
				align: 'center'
			},
			{
				title: t('user.requestType'),
				dataIndex: 'request',
				align: 'center',
				render: (_, { offTypeAcronym, offTypeColor, offTypeName }) => {
					return <Tag color={TYPE_COLORS[offTypeColor]}>{`${offTypeAcronym} - ${offTypeName}`}</Tag>;
				}
			},
			{
				title: t('request.request_day'),
				width: 200,
				dataIndex: 'startDate',
				align: 'center',
				render: (_, record) => {
					return record.startDate && record.endDate === null ? (
						<span className='time_startDate'>{record.startDate}</span>
					) : record.startDate === null && record.endDate ? (
						<span className='time_endDate'>{record.endDate}</span>
					) : record?.monthAndYearForRequestCP ? (
						<span className='time_AllDate'>{`${record?.monthAndYearForRequestCP}`}</span>
					) : (
						<span className='time_AllDate'>{`${record.startDate} - ${record.endDate}`}</span>
					);
				}
			},
			{
				title: t('user.reason'),
				dataIndex: 'reason',
				align: 'center'
			},

			{
				title: t('user.status'),
				dataIndex: 'status',
				width: 125,
				align: 'center',
				render: renderStatus
			},
			{
				title: t('user.leaderComment'),
				dataIndex: 'leaderComment',
				align: 'center'
			},
			{
				title: t('user.reviewerComment'),
				dataIndex: 'reviewerComment',
				align: 'center'
			},
			{
				title: t('common.action'),
				width: 200,
				align: 'center',
				render: (_, record) => {
					const { id } = record;
					return (
						<TableDetails
							id={record?.id}
							isRequest={true}
							typeAcronym={record?.offTypeAcronym}
							disabledDelete={record.status !== PENDING}
							isModify={record.status === PENDING}
							handleDelete={async () => {
								await userRequestApi.delete(id);
								mutate({ ...record });
							}}
						/>
					);
				}
			}
		];
		return columns;
	}, [t, dispatch]);
}
