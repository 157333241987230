import { message } from 'antd';
import axios from 'axios';
import queryString from 'query-string';
import { getToken } from '../common/cookies/Cookie';
import i18n from '../translation/i18n';
import { AUTH_BEARER_TOKEN_KEY } from '../constant';
import { logoutAndRemove } from '../utils/auth';

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {},
	paramsSerializer: params => queryString.stringify(params, { skipNull: true, skipEmptyString: true, encode: false })
});

axiosClient.interceptors.request.use(function (config) {
	config.headers = {
		[AUTH_BEARER_TOKEN_KEY]: getToken() ?? undefined,
		...config?.headers
	};
	if (config.method.toLowerCase() === 'post' && config.headers['Content-Type'] !== 'multipart/form-data') {
		config.headers['Content-Type'] = 'application/json';
	}
	return config;
});

axiosClient.interceptors.response.use(
	function (response) {
		if (response.status === 200) {
			return response;
		}
		return Promise.reject(response);
	},
	function (error) {
		if (error.response.status === 401) {
			message.error(i18n.t('validate.expiredToken'));
			logoutAndRemove();
		}
		if (error.response.status === 403) {
			message.error(i18n.t('validate.forbidden'));
		}
		return Promise.reject(error);
	}
);

export default axiosClient;
