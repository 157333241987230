import React from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../../../../components/FormItem';
import { CommonDatePicker, CommonTimePicker } from '../../../../../../components/datePickers/commonPickers';

export default function LateWork() {
	const { t } = useTranslation();

	return (
		<>
			<FormItem name='startDate' label={t('common.day')} rules={[{ required: true, message: t('message.exactlyDay') }]}>
				<CommonDatePicker />
			</FormItem>
			<FormItem name='timeCheck' label={t('user.time')} rules={[{ required: true, message: t('message.exactlyTime') }]}>
				<CommonTimePicker />
			</FormItem>
		</>
	);
}
