import React from 'react';
import { DatePicker, Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../../../../components/FormItem';
import { DATE_FORMAT, REQUESTS_TYPE } from '../../../../../../constant';

const { RangePicker } = DatePicker;
export default function VacationSalary({ request }) {
	const { t } = useTranslation();
	const form = Form.useFormInstance();
	const options = [
		{
			title: t('user.morning'),
			value: 'true'
		},
		{
			title: t('user.afternoon'),
			value: 'false'
		}
	];
	const onChange = ({ target: { value } }) => {
		if (request.indexOf(REQUESTS_TYPE.half_date) === 0) {
			form.setFieldsValue({
				isHalfDay: true,
				isMorning: value
			});
		} else {
			form.setFieldsValue({
				isHalfDay: false
			});
		}
	};

	return (
		<>
			<FormItem name='rangeDate' label={t('common.day')} rules={[{ required: true, message: t('message.exactlyDay') }]}>
				<RangePicker format={DATE_FORMAT} />
			</FormItem>
			{request?.indexOf(REQUESTS_TYPE.half_date) === 0 ? (
				<Form.Item name='isHalfDay'>
					<FormItem
						name='isMorning'
						label={t('user.vacation')}
						rules={[{ required: true, message: t('message.exactlyTimeOfDay') }]}
					>
						<Radio.Group onChange={onChange}>
							{options.map(option => (
								<Radio value={option.value} key={option.value}>
									{option.title}
								</Radio>
							))}
						</Radio.Group>
					</FormItem>
				</Form.Item>
			) : null}
		</>
	);
}
