/* eslint-disable no-debugger */
import { removeToken, removeUser } from '../common/cookies/Cookie';

export const gotoLogin = () => {
	const url = new URL(window.location.href);
	const rawPath = url.origin + url.pathname;
	window.location.href = `${process.env.REACT_APP_AUTH_URL}/login-modern?redirect=${encodeURIComponent(rawPath)}`;
};

export const logoutAndRemove = () => {
	removeUser();
	removeToken();
	gotoLogin();
};
