import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
function ButtonAdd({ children, handleClick }) {
	return (
		<Button onClick={handleClick} type='primary' icon={<PlusCircleFilled />} className='uppercase'>
			{children}
		</Button>
	);
}

export default ButtonAdd;
