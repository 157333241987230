import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Badge, Button, Layout, PageHeader } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import { logout } from '../app/reducers/authSlice';
import { closeModalUserInfo, openModalUserInfo } from '../app/reducers/formSlice';
import Logo from '../assets/images/logo.svg';
import { useFetch } from '../common/hooks';
import defaultImage from '../components/FormImage/imgDefault/default-profile.png';
import { DAY_OFF, TIMEKEEPING_API_URL, USER_INFO } from '../constant/common/api';
import i18n from '../translation/i18n';
import { checkRoles } from '../utils';
import { logoutAndRemove } from '../utils/auth';
import { AppSidebar } from './AppSidebar/AppSidebar';
import UserInfoFormModal from './UserInfoFormModal';
const { Header, Sider, Content } = Layout;
import '../assets/scss/_layout.scss';
import { setPdays } from '../common/reducer';
function MainLayout() {
	const { data: dataAvatar } = useFetch({ url: USER_INFO, withQuery: false });
	const [collapsed, setCollapsed] = useState(false);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { data } = useFetch({ url: TIMEKEEPING_API_URL, withQuery: false });
	const selectedKeys = pathname.split('/');
	const [dataDayOff, setDataDayOff] = useState();
	const [isUserInfo, setIsUserInfo] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axiosClient.get(DAY_OFF);
				setDataDayOff(response.data);
				dispatch(setPdays(response.data));
			} catch (error) {
				console.error(error);
			}
		};
		fetchData();
	}, []);

	const { title, extra, subTitle } = checkRoles(pathname);

	return (
		<>
			<Layout style={{ minHeight: '100vh' }}>
				<Sider trigger={null} collapsible collapsed={collapsed} width={250}>
					<div className='logo'>
						<img src={Logo} alt='Logo' />
						{!collapsed && <h1>{t('common.hive_tech')}</h1>}
					</div>
					<AppSidebar />
				</Sider>
				<Layout className='site-layout'>
					<Header className='site-layout-background' style={{ padding: 17, borderBottom: '1px solid #ccc' }}>
						{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
							className: 'trigger',
							onClick: () => setCollapsed(!collapsed)
						})}
						<div className='user'>
							<div>
								<a
									href='#!'
									onClick={() => {
										dispatch(openModalUserInfo());
										setIsUserInfo(true);
									}}>
									<p
										style={{
											marginRight: '10px',
											fontWeight: 600,
											display: 'inline-block',
											marginTop: '10px'
										}}>{`${data?.data[0]?.fullName}`}</p>

									<img
										src={dataAvatar?.avatar?.url ? dataAvatar?.avatar?.url : defaultImage}
										alt='UserIcon'
										style={{ height: '40px', width: '40px', borderRadius: '50%' }}
									/>
									{isUserInfo && (
										<UserInfoFormModal
											onClick={() => {
												dispatch(closeModalUserInfo());
											}}
										/>
									)}
								</a>
							</div>
							<div className='user__logout'>
								<Button
									style={{ fontWeight: 600 }}
									type='link'
									onClick={() => {
										dispatch(logout());
										logoutAndRemove();
									}}
									icon={<LogoutOutlined />}>
									{t('common.logout')}
								</Button>
							</div>
						</div>
					</Header>
					<Content className='site-layout-background' style={{ height: '100%' }}>
						<Layout className='site-layout' style={{ height: '100%' }}>
							<Header className='site-layout-background filter' style={{ padding: 0, borderBottom: '1px solid #ccc' }}>
								{selectedKeys[1] === 'request'
									? React.createElement(PageHeader, {
											title,
											extra,
											subTitle: [
												<div key='pDays' style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
													<h2
														style={{
															padding: '10px',
															fontSize: '14px',
															marginTop: '10px',
															marginLeft: '10px',
															color: '#1890ff'
														}}>
														{i18n.t('request.pDaysLeft')}
													</h2>
													<Badge
														count={dataDayOff?.pdaysRemains}
														style={{
															backgroundColor: '#09bae763',
															color: '#1890ff',
															marginTop: 4,
															fontSize: 14
														}}
														showZero
													/>

													<h2
														style={{
															padding: '10px',
															fontSize: '14px',
															marginTop: '10px',
															marginLeft: '10px',
															color: ' #389e0d'
														}}>
														{i18n.t('request.pDayWedding')}
													</h2>
													<Badge
														count={dataDayOff?.weddingDays}
														style={{
															backgroundColor: '#f6ffed',
															color: ' #389e0d',
															marginTop: 4,
															fontSize: 14
														}}
														showZero
													/>

													<h2
														style={{
															padding: '10px',
															fontSize: '14px',
															marginTop: '10px',
															marginLeft: '10px',
															color: '#000000d9'
														}}>
														{i18n.t('request.pDayMourning')}
													</h2>
													<Badge
														count={dataDayOff?.mourning}
														style={{
															backgroundColor: '#fafafa',
															color: '#000000d9',
															marginTop: 4,
															fontSize: 14
														}}
														showZero
													/>

													<Badge
														count={dataDayOff?.notify}
														style={{
															backgroundColor: '#fff7e6',
															color: '#de6b1c',
															border: 10,
															marginTop: 4,
															marginLeft: 20,
															fontSize: 16
														}}
														showZero
													/>
												</div>
											],
											style: { backgroundColor: 'transparent', width: '100%' }
									  })
									: React.createElement(PageHeader, {
											title,
											extra,
											subTitle,
											style: { backgroundColor: 'transparent', width: '100%' }
									  })}
							</Header>
							<Content className='main-content'>
								<Outlet />
							</Content>
						</Layout>
					</Content>
				</Layout>
			</Layout>
		</>
	);
}

export default MainLayout;
