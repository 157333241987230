import { Select } from 'antd';
import React from 'react';
import { useFetch, useFilter } from '../../../common/hooks';
import './UserSelect.scss';

const { Option } = Select;

const checkMatchOption = (input, option) =>
	typeof option === 'string' ? option.toLowerCase().indexOf(input?.replace(/\s+/g, ' ')?.toLowerCase()) >= 0 : false;

function UserSelect({
	keyName,
	parentUrl = '',
	placeHolder,
	url,
	className,
	titleKey = 'fullName',
	valueKey = 'username',
	titleCallback,
	defaultValue,
	onChange,
	search = false,
	...props
}) {
	const { data, loading } = useFetch({ url, withQuery: false });
	const { filter, setFilter } = useFilter();

	const handleOnChange = value => {
		if (search) {
			setFilter({
				...filter,
				[keyName]: value
			});
		}
	};
	return (
		<Select
			allowClear
			loading={loading}
			className={`user-select ${className}`}
			showSearch
			placeholder={placeHolder}
			filterOption={(input, option) => {
				if (option) {
					return checkMatchOption(input, option.props.children) || checkMatchOption(input, option.props.value);
				} else {
					return false;
				}
			}}
			value={filter?.[keyName]}
			onChange={(value, option) => (search ? handleOnChange(value) : onChange(value, option))}
			{...props}>
			{Array.isArray(data) &&
				data?.map(item => {
					return (
						<Option key={item[valueKey]} value={item[valueKey]}>
							{typeof titleCallback === 'function' ? titleCallback(item) : item[titleKey]}
						</Option>
					);
				})}
		</Select>
	);
}

export default UserSelect;
