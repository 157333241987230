import moment from 'moment/moment';
import { DATE_FORMAT, FORMAT_MONTH } from '../constant';

export const GET_DAY_OF_MONTH = (year, month) => {
	return new Date(year, month, 0).getDate();
};

export const formatDisplayDate = date => {
	return moment(date).format(DATE_FORMAT);
};

export const getWorkingDay = month => {
	const thisMonth = moment(month, FORMAT_MONTH);
	const days = thisMonth.daysInMonth();
	const thisDay = thisMonth.clone();
	let workDays = 0;
	for (let i = 1; i <= days; i++) {
		if (thisDay.day() < 6 && thisDay.day() > 0) {
			workDays++;
		}
		thisDay.add(1, 'day');
	}
	return workDays;
};
