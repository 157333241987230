import { UPLOAD_FILE } from '../constant/common/api';
import axiosClient from './axiosClient';

const commonApi = {
	upload: file => {
		const formData = new FormData();
		formData.append('file', file);
		return axiosClient.post(UPLOAD_FILE, formData);
	}
};

export default commonApi;
