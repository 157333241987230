import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TableDetails from '../../../components/TableDetail/TableDetails';
import { userRequestApi } from '../../../api/userRequestApi';
import { GET_USERS_SETTING } from '../../../constant/common/api';
import { useFetch } from '../../../common/hooks';
import { allRole } from '../../../constant';
import { Tag } from 'antd';

export default function useCreateColumns({ mutate }) {
	const { data } = useFetch({ url: `${GET_USERS_SETTING}` });
	const dispatch = useDispatch();
	const { t } = useTranslation(undefined, {
		keyPrefix: 'admin'
	});
	const { t: c } = useTranslation(undefined, {
		keyPrefix: 'common'
	});

	return useMemo(() => {
		const columns = [
			{
				title: c('id'),
				align: 'center',
				width: 60,
				render: (_, _record, index) => {
					return (data?.currentPage - 1) * 10 + index + 1 || index + 1;
				}
			},
			{
				title: t('userName'),
				align: 'center',
				dataIndex: 'username',
				sorter: true
			},
			{
				title: t('fullName'),
				align: 'center',
				dataIndex: 'fullName'
			},
			{
				title: t('role'),
				align: 'center',
				dataIndex: 'roles',
				sorter: true,
				render: roleSet => {
					return roleSet?.map(item => {
						const role = allRole.find(x => x.value === item);
						return (
							<Tag key={item} color={role?.color}>
								{role?.label}
							</Tag>
						);
					});
				}
			},
			{
				title: t('status'),
				align: 'center',
				dataIndex: 'active',
				sorter: true,
				render: active =>
					active ? (
						<CheckCircleFilled style={{ fontSize: '20px', color: '#78BE20' }} />
					) : (
						<CloseCircleFilled style={{ fontSize: '20px', color: '#F94144' }} />
					)
			},
			{
				title: c('action'),
				align: 'center',
				dataIndex: 'userName',
				render: (_, record) => {
					const { username, fullName } = record;
					return (
						<TableDetails
							fullName={fullName}
							id={record}
							handleDelete={async () => {
								await userRequestApi.deleteUsers(username);
								mutate({ ...record });
							}}
						/>
					);
				}
			}
		];
		return columns;
	}, [t, dispatch, data]);
}
