import { t } from 'i18next';
import React from 'react';
import Work from '../../../assets/images/work.svg';
import Cease from '../../../assets/images/cease.svg';
import Remote from '../../../assets/images/remote.svg';
import StatisticCard from '../../../components/StatisticCard';
import TableStatistic from './TableStatistic';
import { useFetch } from '../../../common/hooks';
import { STATISTICAL_API_URL } from '../../../constant/common/api';
function StatisticCardGroup() {
	const data = useFetch({ url: STATISTICAL_API_URL, withQuery: true, requiredParams: ['date'] });

	const dataInCard = [
		{
			title: t('leader.statistic.work'),
			count: data?.data?.totalWork,
			img: Work
		},
		{
			title: t('leader.statistic.off'),
			count: data?.data?.totalOff,
			img: Cease
		},
		{
			title: t('leader.statistic.remote'),
			count: data?.data?.totalRemote,
			img: Remote
		}
	];
	return (
		<div className='statistic'>
			<div className='statistic__card--group'>
				{dataInCard.map((item, index) => (
					<StatisticCard title={item.title} count={item.count} image={item.img} key={index} />
				))}
			</div>
			<TableStatistic data={data} />
		</div>
	);
}

export default StatisticCardGroup;
