import React, { memo, useCallback, useState } from 'react';
import { accountantApi } from '../../../api/acountantApi';
import { FileUnknownOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { ReactComponent as SVGExcel } from '../../../assets/images/excel.svg';

const initialUploadProps = {
	class: '',
	content: 'Nhấp chuột hoặc kéo thả file để upload',
	icon: <InboxOutlined />
};

function DragUploadFile({ onSuccess: handleSuccess }) {
	const [uploadProps, setUploadProps] = useState(initialUploadProps);
	const [fileList, setFileList] = useState([]);
	const [progress, setProgress] = useState(0);

	const handleUpload = useCallback(file => {
		setFileList([file]);
		setUploadProps({ class: 'upload-success', content: `File excel “${file?.name}”`, icon: <SVGExcel /> });
	}, []);

	const handleRemove = useCallback(
		file => {
			const updatedFileList = fileList.filter(item => item.uid !== file.uid);
			setFileList(updatedFileList);
			setUploadProps(initialUploadProps);
		},
		[fileList]
	);

	const handleRequest = useCallback(
		async ({ onSuccess, onProgress, file }) => {
			const config = {
				headers: { 'content-type': 'multipart/form-data' },
				onUploadProgress: event => {
					const percent = Math.floor((event.loaded / event.total) * 100);
					setProgress(percent);
					if (percent === 100) {
						setTimeout(() => setProgress(0), 1000);
					}
					onProgress({ percent: (event.loaded / event.total) * 100 });
				}
			};

			try {
				const { data } = await accountantApi.upload(file, config);
				handleSuccess(data);
				handleUpload(file);
				onSuccess('ok');
			} catch (error) {
				setUploadProps({
					class: 'upload-error',
					content: 'Tải tập tin thất Bại',
					icon: <FileUnknownOutlined style={{ color: 'red' }} />
				});
			}
		},
		[handleSuccess, handleUpload]
	);

	return (
		<Dragger
			fileList={fileList}
			className={uploadProps.class}
			maxCount={1}
			accept='.xlsx'
			style={{ padding: '0 56px' }}
			multiple={false}
			onDrop={e => {
				console.log('Dropped files', e.dataTransfer.files);
			}}
			onRemove={handleRemove}
			customRequest={handleRequest}>
			<p className='ant-upload-drag-icon'>{uploadProps.icon}</p>
			<h3 className='ant-upload-text'>{uploadProps.content}</h3>
			<p className='ant-upload-hint'>Dữ liệu được nhập từ tệp excel chuẩn hóa sẽ được import vào bảng Phiếu lương nhân viên.</p>
			{progress > 0 ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} /> : null}
		</Dragger>
	);
}

export default memo(DragUploadFile);
