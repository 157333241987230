import { Button, Form, Input, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONFIG_TIME, MEMBERS } from '../../../../constant/common/api';
import { useFetch } from '../../../../common/hooks';
import { DATE_FORMAT, FORMAT_HH_MM } from '../../../../constant';
import moment from 'moment';
import UserSelect from '../../../../components/CommonFields/UserSelect';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { leaderApi } from '../../../../api/leaderApi';
import { CommonDatePicker, CommonTimePicker } from '../../../../components/datePickers/commonPickers';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, formName, ...restProps }) => {
	const { t } = useTranslation();
	const inputNode =
		inputType === 'selector' ? (
			<UserSelect
				parentUrl='/setting/config_time'
				key='/setting/config_time'
				url={MEMBERS}
				keyName={'username'}
				placeHolder={t('common.userName')}
			/>
		) : inputType === 'text' ? (
			<Input />
		) : inputType === 'date' ? (
			<CommonDatePicker />
		) : (
			<CommonTimePicker />
		);
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={formName ?? dataIndex + '-data'}
					style={{
						margin: 0
					}}
					rules={[
						{
							required: true,
							message: t('message.pleaseInputText', { val: `${title}` })
						}
					]}>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
const ConfigTime = () => {
	const { t } = useTranslation();
	const { data, mutate } = useFetch({ url: `${CONFIG_TIME}/employees` });
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const isDisableBtn = editingKey !== '';
	const isAdd = typeof editingKey !== 'number';
	const isEditing = record => record.id === editingKey;

	const edit = record => {
		form.setFieldsValue({
			fullname: '',
			dateFrom: '',
			dateTo: '',
			timeBeginWage1: '',
			timeEndWage1: '',
			timeBeginWage2: '',
			timeEndWage2: '',
			...record
		});
		setEditingKey(record.id);
	};

	const cancel = () => {
		setDataSource(data);
		setEditingKey('');
		form.resetFields();
	};
	const save = async id => {
		try {
			const row = await form.validateFields();
			const formatRow = {};
			for (const key in row) {
				if (Object.hasOwnProperty.call(row, key)) {
					let element = row[key];
					if (moment.isMoment(element)) {
						element = element.format(element._f);
					}
					formatRow[key.replace('-data', '')] = element;
				}
			}
			if (isAdd) {
				await leaderApi.add_configTime(formatRow.username, formatRow);
			} else {
				await leaderApi.edit_configTime(id, formatRow);
			}
			mutate();
			setEditingKey('');
			form.resetFields();
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};
	const handleDelete = async id => {
		const newData = data?.filter(item => item.id !== id);
		await leaderApi.delete_configTime(id);
		mutate({ ...newData });
	};

	const columns = [
		{
			title: t('common.number_order'),
			align: 'center',
			width: '5%',
			inputType: 'text',
			render: (_, record, index) => {
				return index + 1;
			}
		},
		{
			title: t('admin.fullName'),
			align: 'center',
			width: '14%',
			inputType: 'selector',
			dataIndex: 'fullname',
			formName: 'username',
			editable: isAdd
		},
		{
			title: t('admin.dateFrom'),
			align: 'center',
			width: '12%',
			inputType: 'date',
			dataIndex: 'dateFrom',
			editable: true
		},
		{
			title: t('admin.dateTo'),
			align: 'center',
			width: '12%',
			inputType: 'date',
			dataIndex: 'dateTo',
			editable: true
		},
		{
			title: t('admin.timeBeginWage1'),
			align: 'center',
			width: '10%',
			inputType: 'time',
			dataIndex: 'timeBeginWage1',
			editable: true
		},
		{
			title: t('admin.timeBeginWage2'),
			align: 'center',
			width: '10%',
			inputType: 'time',
			dataIndex: 'timeEndWage1',
			editable: true
		},
		{
			title: t('admin.timeEndWage1'),
			align: 'center',
			inputType: 'time',
			dataIndex: 'timeBeginWage2',
			width: '10%',
			editable: true
		},
		{
			title: t('admin.timeEndWage2'),
			align: 'center',
			width: '10%',
			inputType: 'time',
			dataIndex: 'timeEndWage2',
			editable: true
		},
		{
			title: t('common.action'),
			align: 'center',
			width: '20%',
			render: (_, record) => {
				const editable = isEditing(record);
				return (
					<>
						{editable ? (
							<span>
								<Typography.Link
									onClick={() => save(record.id)}
									style={{
										marginRight: 8
									}}>
									<Button type='link'>{t('common.save')}</Button>
								</Typography.Link>
								<Popconfirm title={t('message.popConfirm', { val: `${t('common.cancel')}` })} onConfirm={cancel}>
									<Button type='link'>{t('common.cancel')}</Button>
								</Popconfirm>
							</span>
						) : (
							<Typography.Link disabled={isDisableBtn} onClick={() => edit(record)}>
								<Button type='link' disabled={isDisableBtn}>
									{t('common.modify')}
								</Button>
							</Typography.Link>
						)}

						{data?.length >= 1 && !editable ? (
							<Popconfirm
								title={t('message.popConfirm', { val: `${t('common.delete')}` })}
								onConfirm={() => handleDelete(record.id)}
								disabled={isDisableBtn}>
								<Button type='link' disabled={isDisableBtn}>
									{t('common.delete')}
								</Button>
							</Popconfirm>
						) : null}
					</>
				);
			}
		}
	];
	const mergedColumns = columns.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: record => ({
				record,
				...col,
				editing: isEditing(record)
			})
		};
	});

	const preProcessData = data => {
		if (data) {
			const newData = data ? JSON.parse(JSON.stringify(data)) : undefined;
			mergedColumns.forEach(col => {
				if (col.inputType === 'date') {
					newData.forEach((item, i) => {
						const value = newData[i][col.dataIndex];
						newData[i][col.dataIndex + '-data'] = value ? moment(value, DATE_FORMAT) : undefined;
					});
				} else if (col.inputType === 'time') {
					newData.forEach((item, i) => {
						const value = newData[i][col.dataIndex];
						newData[i][col.dataIndex + '-data'] = value ? moment(value, FORMAT_HH_MM) : undefined;
					});
				} else {
					newData.forEach((item, i) => {
						const value = newData[i][col.dataIndex];
						newData[i][col.dataIndex + '-data'] = value;
					});
				}
			});
			return newData;
		}
	};
	const [dataSource, setDataSource] = useState();

	useEffect(() => {
		if (data) {
			setDataSource(data);
			setEditingKey('');
		}
	}, [data]);

	const handleAdd = () => {
		const today = moment().format(DATE_FORMAT);
		const newData = {
			'fullname-data': '',
			'username-data': '',
			'dateFrom-data': moment(today, DATE_FORMAT),
			'dateTo-data': moment(today, DATE_FORMAT),
			'timeBeginWage1-data': moment('08:00', FORMAT_HH_MM),
			'timeEndWage1-data': moment('12:00', FORMAT_HH_MM),
			'timeBeginWage2-data': moment('13:15', FORMAT_HH_MM),
			'timeEndWage2-data': moment('17:15', FORMAT_HH_MM),
			id: 'id'
		};
		form.setFieldsValue(newData);
		setEditingKey('id');
		setDataSource([...data, newData]);
	};
	return (
		<Form form={form} component={false} onFinish={save}>
			<Button
				disabled={isDisableBtn}
				onClick={handleAdd}
				type='primary'
				style={{
					marginBottom: 16
				}}>
				{t('admin.addConfigTime')}
			</Button>
			<Table
				components={{
					body: {
						cell: EditableCell
					}
				}}
				bordered
				dataSource={preProcessData(dataSource)}
				columns={mergedColumns}
				rowClassName='editable-row'
				pagination={{
					onChange: cancel
				}}
			/>
		</Form>
	);
};
export default ConfigTime;
