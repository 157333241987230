import { Button, DatePicker, Form, Input, message } from 'antd';
import ETable from '../../../../components/ETable';
import './ConfigLeaveMode.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch, useFilter } from '../../../../common/hooks';
import { CONFIG_PDAYS, HOLIDAYS } from '../../../../constant/common/api';
import { leaderApi } from '../../../../api/leaderApi';
import useHolidayColumn from './HolidayColumn';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constant';
import { ClearOutlined } from '@ant-design/icons';
import { formatDisplayDate } from '../../../../utils/dateTime';

const { RangePicker } = DatePicker;

const Information = () => {
	const { t } = useTranslation();
	const [isModify, setIsModify] = useState(false);
	const { data, mutate } = useFetch({ url: CONFIG_PDAYS });
	const [form] = Form.useForm();

	const handleModifyLeave = () => {
		setIsModify(true);
	};

	const getContentModifyBtn = () => {
		if (isModify) {
			return t('common.save');
		} else {
			return t('common.modify');
		}
	};

	useEffect(() => {
		if (data) {
			form.setFieldsValue({ ...data });
		}
	}, [data]);

	const submitLeave = async leaves => {
		try {
			setIsModify(false);
			await leaderApi.config_pdays(leaves);
			mutate();
			message.success(t('message.config_leave-success'));
		} catch {
			message.error(t('message.config_leave-error'));
		}
	};
	return (
		<div className='leave'>
			<h3 className='mode-config'>{t('admin.leave_mode')}</h3>

			<div className='on-leave'>
				<Form form={form} className='on-leave__form' disabled={!isModify} onFinish={submitLeave}>
					<Form.Item label={t('admin.configAddEveryMonth')} name='configAddEveryMonth'>
						<Input type='number' className='on-leave__input' step={0.5} min={0} />
					</Form.Item>

					<Form.Item label={t('admin.configMouringInYear')} name='configMouringInYear'>
						<Input type='number' className='on-leave__input' step={0.5} min={0} />
					</Form.Item>
					<Form.Item label={t('admin.configWeddingInPerson')} name='configWeddingInPerson'>
						<Input type='number' className='on-leave__input' step={0.5} min={0} />
					</Form.Item>
				</Form>
				<Button
					className='on-leave__btn'
					type='primary'
					htmlType='submit'
					onClick={() => {
						handleModifyLeave();
						if (isModify) {
							form.submit();
						}
					}}>
					{getContentModifyBtn()}
				</Button>
			</div>
		</div>
	);
};

const ConfigLeaveMode = () => {
	const { t } = useTranslation();
	const { filter, setFilter } = useFilter();
	const { data, mutate } = useFetch({ url: HOLIDAYS });
	const columns = useHolidayColumn();
	const [form] = Form.useForm();

	const onReset = () => {
		form.resetFields();
		setFilter({
			...filter,
			dateFrom: undefined,
			dateTo: undefined,
			keyword: undefined
		});
	};

	useEffect(() => {
		const time = [moment(filter.dateFrom, DATE_FORMAT), moment(filter.dateTo, DATE_FORMAT)];
		if (filter.dateFrom & filter.dateTo) {
			form.setFieldsValue({ ...filter, time });
		} else {
			form.setFieldsValue({ ...filter });
		}
	}, [filter]);

	const handleSubmit = values => {
		const { keyword } = values;
		let newValues = { dateFrom: undefined, dateTo: undefined };
		if (values.time) {
			const dateFrom = formatDisplayDate(values?.time[0]);
			const dateTo = formatDisplayDate(values?.time[1]);
			newValues = { dateFrom, dateTo };
		}

		setFilter({ ...filter, ...newValues, keyword });
		// mutate();
	};

	return (
		<>
			<Information />
			<ETable
				header={
					<div className='holidays'>
						<h3 className='mode-config'>{t('admin.holiday_mode')}</h3>
						<Form
							className='holidays-filter'
							key='Form'
							layout='inline'
							form={form}
							style={{ marginTop: '8px' }}
							onFinish={handleSubmit}>
							<div className='holidays-filter__form'>
								<Form.Item className='holidays-filter__form--item' label={t('common.setup_time')} name='time'>
									<RangePicker key='RangePicker' valueKey='time' format={DATE_FORMAT} />
								</Form.Item>

								<Form.Item className='holidays-filter__form--item' label={t('admin.find_holiday')} name='keyword'>
									<Input placeholder={t('admin.find_holiday')}></Input>
								</Form.Item>
								<Form.Item className='holidays-filter__form--item'>
									<ClearOutlined type='primary' style={{ fontSize: '20px' }} onClick={onReset} />
								</Form.Item>
							</div>

							<Form.Item className='search-btn'>
								<Button type='primary' htmlType='submit'>
									{t('filter.search')}
								</Button>
							</Form.Item>
						</Form>
					</div>
				}
				dataSource={data?.data}
				columns={columns}
				isShowUtilBar={false}
			/>
		</>
	);
};

export default ConfigLeaveMode;
