/* eslint-disable no-debugger */
/* eslint-disable semi */
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetToken } from './utils';
import { loginSuccess } from '../app/reducers/authSlice';
import { WrongTokenPage } from './WrongTokenPage';
import '../App.css';
import '../assets/scss/app.scss';
import { Routes } from '../routes/Routes';
import { useQuery } from '@tanstack/react-query';
import { authApi } from '../api/authApi';

const regexRole = /r:(.+)/;

export const Root = () => {
	const dispatch = useDispatch();
	const token = useGetToken();

	const { status, data } = useQuery({
		queryKey: ['verifyToken', token],
		queryFn: async () => {
			const { data } = await authApi.verifyToken(token);
			await authApi.updateUserInfo(data, token);

			const regexRoles = data?.role?.filter(item => item.includes('COSIC_')).map(role => role?.match(regexRole)?.[1]?.trim());
			return { ...data, regexRoles };
		},
		enabled: !!token,
		retry: 1
	});

	useLayoutEffect(() => {
		if (data) {
			dispatch(loginSuccess(data));
		}
	}, [data]);

	switch (status) {
		case 'loading': {
			return <span>Đang kiểm tra token...</span>;
		}
		case 'error': {
			return <WrongTokenPage />;
		}
		case 'success': {
			return <Routes role={data?.regexRoles} />;
		}
	}
};
