import React, {useEffect, useState} from 'react';
import {Form, Input, Select} from 'antd';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import FormItem from '../components/FormItem';
import UserSelect from '../components/CommonFields/UserSelect';
import {URL_ACCEPTED_BY, USER_INFO} from '../constant/common/api';
import {userRequestApi} from '../api/userRequestApi';
import {useFetch} from '../common/hooks';
import FormUserInfo from '../components/FormUserInfo';
import {allRole, ROLE} from '../constant';
import {FormImage} from '../components/FormImage/FormImage';

function UserInfoFormModal() {
	const {t} = useTranslation();
	const {data, loading, mutate} = useFetch({url: USER_INFO, withQuery: false});
	const {userInfoVisible} = useSelector(state => state.form);
	const {roles} = useSelector(state => state.auth);
	const [form] = Form.useForm();

	const [listFileUpload, setListFileUpload] = useState();

	useEffect(() => {
		setListFileUpload(null);
	}, []);

	useEffect(() => {
		if (data) {
			form.setFieldsValue({
				fullName: data?.fullName,
				username: data?.username,
				macId: data?.macId,
				macIdUnifi: data?.macIdUnifi,
				roleSet: data?.roles,
				isLeader: data?.isLeader,
				avatar: data?.avatar,
				isPartTime : data?.isPartTime
			});
		}
	}, [data, loading, userInfoVisible]);

	const convertAvatar = item => {
		const { fileName, file } = item || {};
		if (fileName) {
			return { name: fileName };
		} else if (file) {
			return {
				file,
				name: ''
			};
		} else {
			return {};
		}
	};

	const handleSubmit = async value => {
		const { macId, macIdUnifi, avatar } = value;
		const avatarConverted = convertAvatar(avatar);
		const newValue = {
			macId,
			macIdUnifi
			// avatar: avatarConverted
		};

		await userRequestApi.edit_infoUser(newValue);
		mutate();
	};
	const translationKeyForIsPartTime = form.getFieldValue('isPartTime') ? t('Part-time') : t('full-time');
	return (
		<FormUserInfo form={form} title={t('common.userInfo')} onSubmit={handleSubmit}>
			<Form.Item name={'fullName'} label={t('admin.fullName')} disabled={true} rules={[{ required: true }]}>
				<Input disabled />
			</Form.Item>

			<Form.Item name='username' label={t('admin.userName')} disabled={true} rules={[{ required: true }]}>
				<Input disabled />
			</Form.Item>

			<Form.Item
				name='macId'
				label={t('admin.macId')}
				rules={roles?.includes(ROLE.COSIC_ADMIN) ? [{ required: false }] : [{ required: true }]}>
				<Input />
			</Form.Item>

			<Form.Item name='macIdUnifi' label={t('admin.macIdUnifi')} rules={[{ required: false }]}>
				<Input />
			</Form.Item>

			<Form.Item label={t('Nhân viên')} disabled={true}>
				<Input value={translationKeyForIsPartTime} disabled />
			</Form.Item>

			<FormItem name='roles' label={t('admin.role')} disabled={true}>
				<Select allowClear mode='multiple' options={allRole} disabled showArrow />
			</FormItem>

			<FormItem name='isLeader' label='Leader' type='text' disabled={true}>
				<UserSelect
					url={`${URL_ACCEPTED_BY}/${data?.username}/leaders`}
					valueKey={'userName'}
					titleCallback={obj => {
						return `${obj.firstName} ${obj.lastName}`;
					}}
					disabled
				/>
			</FormItem>
			<Form.Item name='avatar' label='Avatar'>
				<FormImage isAvatar={true} />
			</Form.Item>
		</FormUserInfo>
	);
}

export default UserInfoFormModal;
