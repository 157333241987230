import React from 'react';
import { DATE_FORMAT, FORMAT_HH_MM } from '../../constant';
import { DatePicker, TimePicker } from 'antd';

export function CommonDatePicker(props) {
	return <DatePicker format={DATE_FORMAT} {...props} />;
}

export function CommonTimePicker(props) {
	return <TimePicker format={FORMAT_HH_MM} hideDisabledOptions {...props} />;
}

CommonTimePicker.defaultProps = {
	disabledTime: () => ({
		disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]
	})
};
