import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LIST_STATUS } from '../../../constant';

export default function TableStatistic({ data }) {
	const { t } = useTranslation();

	const column = [
		{
			title: t('common.name'),
			dataIndex: 'username',
			width: '15%',
			align: 'center',
			sorter: true,
			render: (_, record) => {
				return <span>{record?.username}</span>;
			}
		},

		{
			title: t('user.status'),
			dataIndex: 'status',
			width: '15%',
			align: 'center',
			sorter: true,
			filters: LIST_STATUS,
			onFilter: (value, record) => {
				return record?.status.indexOf(value) === 0;
			},
			render: (_, record) => {
				return <span>{record?.status}</span>;
			}
		},
		{
			title: t('common.reason'),
			dataIndex: 'reason',
			align: 'center',
			width: '70%',
			render: (_, record) => {
				return <span>{record?.reason}</span>;
			}
		}
	];
	return (
		<div className='statistic__table'>
			<Table
				columns={column}
				dataSource={data?.data?.statisticalDTOList}
				pagination={false}
				style={{ padding: 10 }}
				scroll={{ y: 350 }}
			/>
		</div>
	);
}
