import UserSelect from '../components/CommonFields/UserSelect';
import MonthPicker from '../components/datePickers/MonthPicker';
import Filter from '../components/Filter';
import DropDownA from '../pages/accountant/components/DropDownA';
import i18n from '../translation/i18n';
import { MEMBERS } from './common/api';

export const PAGE_HEADER_VALUE = {
	'/approve': {
		title: i18n.t('common.approve')
	},
	'/time_keeping': {
		title: i18n.t('common.timekeeping_history'),
		subTitle: <MonthPicker />
	},
	'/request': {
		title: i18n.t('common.request')
	},
	'/history': {
		title: i18n.t('common.edited_history'),
		extra: <Filter />,
		subTitle: <MonthPicker />
	},
	'/regulation_leave': {
		title: i18n.t('common.regulation_leave')
	},
	'/setting': {
		title: i18n.t('common.setting'),
		extra: <Filter />
	},
	'/setting/config_leaveMode': {
		title: i18n.t('admin.config_leaveMode')
	},
	'/setting/setup_link': {
		title: i18n.t('common.setup_link')
	},
	'/setting/setup_user': {
		title: i18n.t('common.setup_user')
	},
	'/setting/config_time': {
		title: i18n.t('common.setup_time')
	},
	'/statistic/timekeeping_table': {
		title: i18n.t('common.timekeeping_table'),
		extra: <Filter />,
		subTitle: <MonthPicker />
	},
	'/statistic/general_statistic': {
		title: i18n.t('common.general_statistic'),
		extra: <Filter />,
		subTitle: <MonthPicker />
	},
	'/statistic/personal_statistic': {
		title: i18n.t('common.personal_statistic'),
		subTitle: [
			<UserSelect
				search={true}
				keyName='username'
				allowClear={false}
				parentUrl='/statistic/personal_statistic'
				key='/statistic/personal_statistic'
				url={MEMBERS}
				placeHolder={i18n.t('common.userName')}
			/>,
			<MonthPicker key='DatePick' />
		]
	},
	'/leader_statistic': {
		title: i18n.t('common.statistic')
	},
	'/accountant': {
		title: i18n.t('common.accountant'),
		subTitle: <MonthPicker key='DatePick' />,
		extra: <DropDownA />
	}
};
