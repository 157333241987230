import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import './ConfigLeaveMode.scss';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export default function useHolidayColumn() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return useMemo(() => {
		const columns = [
			{
				title: t('admin.holiday_name'),
				dataIndex: 'description',
				align: 'center'
			},
			{
				title: t('admin.holiday_date'),
				dataIndex: 'date',
				width: 150,
				align: 'center'
			},
			{
				title: t('admin.status'),
				dataIndex: 'status',
				width: 150,
				align: 'center',
				render: status => {
					return status === 1 ? (
						<CheckCircleOutlined style={{ color: '#52c41a' }} />
					) : (
						<CloseCircleOutlined style={{ color: '#389e0d' }} />
					);
				}
			}
		];
		return columns;
	}, [t, dispatch]);
}
