export const tinyPlugins = [
	'advlist autolink lists link image',
	'charmap print preview anchor help',
	'searchreplace visualblocks code',
	'insertdatetime media table paste wordcount',
	'table'
];

export const tinyToolbar =
	'undo redo | fontfamily fontsize | backcolor | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | bullist numlist outdent indent | help';
