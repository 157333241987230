import React, { useCallback, useEffect, useState } from 'react';
import ETable from '../../components/ETable';
import { createAccountantColumns } from './createAccountantColumns';
import { useFetch, useFilter } from '../../common/hooks';
import { FORMAT_MONTH } from '../../constant';
import moment from 'moment';
import ModalLoadFormData from './components/ModalLoadFormData';
import './style.scss';
import { URL_ACCOUNTANT_MEMBERS } from '../../constant/common/api';
import { useDispatch, useSelector } from 'react-redux';
import { accountantApi } from '../../api/acountantApi';
import { Skeleton } from 'antd';
import { exportFile } from '../../utils/exportFile';
import ModalSendsEmail from './components/ModalSendsEmail';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import ModalInputPin from './components/ModalInputPin';
import { openModal } from '../../app/reducers/formSlice';
import CreatePin from './components/CreatePin';
import TextSecurity from './components/TextSecurity';

const Accountant = () => {
	const { filter } = useFilter();
	const { id } = useSelector(state => state.form);

	const dispatch = useDispatch();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [isToggle, setIsToggle] = useState(true);
	const thisMonth = filter?.date && moment(filter?.date, FORMAT_MONTH).month() + 1;

	const { data, error, mutate } = useFetch({ url: URL_ACCOUNTANT_MEMBERS });
	const isNotAllow = error?.response?.status === 412;

	const exportExcelFile = useCallback(async () => {
		if (selectedRowKeys.length === 0) {
			return {
				type: 'info',
				msg: 'Vui Lòng chọn bản ghi!'
			};
		} else {
			const { data } = await accountantApi.exportExcelMembers(selectedRowKeys, filter?.date);
			exportFile(data, `BẢNG_LƯƠNG_THÁNG_${thisMonth}.zip`, 'application/zip');
		}
	}, [filter?.date, selectedRowKeys]);

	useEffect(() => {
		if (isNotAllow) {
			dispatch(openModal('verifyPin'));
		}
	}, [isNotAllow]);

	const showModal = () => {
		switch (id) {
			case 'upload':
				return !isNotAllow && <ModalLoadFormData />;
			case 'sends':
				return !isNotAllow && <ModalSendsEmail dataSource={data?.data} />;
			case 'verifyPin':
				return <ModalInputPin mutate={mutate} />;
			case 'createPin':
			case 'editPin':
				return <CreatePin mutate={mutate} />;
		}
	};

	return (
		<>
			{isNotAllow ? (
				<Skeleton />
			) : (
				<ETable
					header={
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<h4 style={{ marginBottom: 0 }}>Tổng tiền lương tháng {thisMonth}</h4>
							<h2 style={{ marginBottom: 8 }}>
								<TextSecurity isToggle={isToggle} number={data?.companySalary} />{' '}
								<span onClick={() => setIsToggle(!isToggle)}>{isToggle ? <EyeInvisibleOutlined /> : <EyeOutlined />}</span>
							</h2>
						</div>
					}
					className='historyTable'
					columns={createAccountantColumns(isToggle)}
					dataSource={isNotAllow ? undefined : data?.data}
					pagination={{
						total: data?.totalPages
					}}
					handleExport={exportExcelFile}
					rowSelection={{
						selectedRowKeys,
						onChange: setSelectedRowKeys
					}}
					rowKey='username'
				/>
			)}
			{showModal()}
		</>
	);
};

export default Accountant;
