import { ACCEPT, CANCELED, REJECT, REVIEWED } from '../constant';
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export * from './handleQueryFunc';
export * from './checkRole';
export * from './notifications';

export const formatNum = num => {
	const newNum = +num;
	if (isNaN(newNum)) {
		return num;
	}
	return Math.round((newNum + Number.EPSILON) * 100) / 100;
};

const statusConfig = {
	[ACCEPT]: { icon: <CheckCircleOutlined />, color: 'success' },
	[REJECT]: { icon: <CloseCircleOutlined />, color: 'error' },
	[CANCELED]: { icon: <CloseCircleOutlined />, color: 'warning' },
	[REVIEWED]: { icon: <CheckCircleOutlined />, color: 'processing' },
	default: { icon: <MinusCircleOutlined />, color: 'default' }
};

export const renderStatus = status => {
	const config = statusConfig[status] || statusConfig.default;
	return <Tag {...config}>{status}</Tag>;
};
