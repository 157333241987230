import { useSelector } from 'react-redux';
import { SidebarConfig } from './configSilbar';
import { calculateRouteItems, flattenRoutes, isShowSidebarItem } from './routes.utils';
import { Roles } from './Roles';

export const useUserRoutes = () => {
	const { roles } = useSelector(state => state.auth);

	const userRoles = Roles.map(role => isShowSidebarItem(roles, role));
	const sidebarUserRoles = SidebarConfig.map(role => isShowSidebarItem(roles, role));

	return { userRoles: calculateRouteItems(userRoles), sidebarUserRoles };
};

export const useUserFlattenRoutes = () => {
	const { userRoles, sidebarUserRoles } = useUserRoutes();
	const { roles } = useSelector(state => state.auth);

	return { sidebarUserRoles: flattenRoutes(roles, sidebarUserRoles), userRoles };
};
