export const appKeys = {
	app: 'cs',

	timeKeeping: 'cs.time_keeping',
	request: 'cs.request',
	history: 'cs.history',
	regulationLeave: 'cs.regulation_leave',

	// Common
	list: 'list',
	detail: 'detail',
	leaderStatistic: 'cs.leader_statistic',
	statistic: 'cs.statistic',
	timeKeepingTable: 'cs.statistic.timekeeping_table',
	generalStatistic: 'cs.statistic.general_statistic',
	personalStatistic: 'cs.statistic.personal_statistic',
	approve: 'cs.approve',

	setting: 'cs.setting',
	settingUser: 'cs.setting.setup_user',
	settingLink: 'cs.setting.setup_link',
	settingConfigTime: 'cs.setting.setup_config_time',
	settingConfigLeaveMode: 'cs.setting.setup_config_leave_mode',
	accountant: 'cs.salary'
};
