import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../../../../components/FormItem';
import { REQUESTS_TYPE } from '../../../../../../constant';
import { CommonDatePicker, CommonTimePicker } from '../../../../../../components/datePickers/commonPickers';

export default function CheckInForm({ request }) {
	const { t } = useTranslation();
	const isCheckIn = request === REQUESTS_TYPE.checkin;

	return (
		<>
			<FormItem name='startDate' label={t('common.day')} rules={[{ required: true, message: t('message.exactlyDay') }]}>
				<CommonDatePicker
					disabledDate={current => {
						return current > moment().endOf('day');
					}}
				/>
			</FormItem>
			<FormItem
				name='timeCheck'
				label={t(isCheckIn ? 'user.timeCheckin' : 'user.timeCheckout')}
				rules={[{ required: true, message: t('message.exactlyTime') }]}>
				<CommonTimePicker />
			</FormItem>
		</>
	);
}
