import { Button, Form, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalUserInfo, openModalUserInfo } from '../../app/reducers/formSlice';
import { showConfirmModal, showErrMsg } from '../../utils';
import { useLocation } from 'react-router-dom';

export default function FormUserInfo({
	children,
	onSubmit,
	defaultValue,
	layout = 'vertical',
	handleClose,
	form,
	title,
	isAdd,
	successMessage,
	handleChange,
	disabledFeature,
	mutate,
	approve,
	record,
	setFormType,
	...props
}) {
	const { pathname } = useLocation();

	const currentPage = pathname.slice(1);

	const { userInfoVisible, isModify } = useSelector(state => state.form);
	const [modifyForm, setModifyForm] = useState(false);
	useEffect(() => {
		if (currentPage !== 'request') {
			setModifyForm(true);
		}
	}, [currentPage]);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const close = () => {
		if (typeof handleClose === 'function') {
			handleClose();
		}
		dispatch(closeModalUserInfo());
	};

	const closeForm = () => {
		if (form.isFieldsTouched()) {
			showConfirmModal({
				title: t('message.modalDelete'),
				onOk: close
			});
		} else {
			close();
		}
	};

	const submitForm = async values => {
		if (typeof onSubmit === 'function') {
			setLoading(true);
			try {
				await onSubmit(values);
				close();
				message.success(successMessage ?? (isAdd ? t('message.success') : t('message.editInfoSuccess')));
			} catch (error) {
				showErrMsg(error);
			}
			setLoading(false);
		}
	};

	const [componentDisabled, setComponentDisabled] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const handleFormChange = () => {
		setIsFormTouched(form.isFieldsTouched());
		setComponentDisabled(false);
	};

	const disabled = !isAdd && componentDisabled;

	const changeToEdit = () => {
		if (disabled === true && disabledFeature) {
			setComponentDisabled(state => !state);
		} else if (isFormTouched) {
			setModifyForm(state => !state);
			form.submit();
		} else {
			form.submit();
		}
	};

	const getContentSubmitBtn = () => {
		if (isAdd) {
			return t('common.btn_submit_default');
		}
		if ((disabled && isModify) || !isFormTouched || (disabled && modifyForm) || disabled) {
			return t('common.modify');
		} else {
			return t('common.save');
		}
	};
	useEffect(() => {
		if (disabledFeature) {
			setComponentDisabled(true);
		}
		setIsFormTouched(state => !state);
	}, [userInfoVisible]);

	const actionApproveClick = () => {
		setFormType('approveForm');
		dispatch(openModalUserInfo(form.getFieldsValue()));
	};
	const reason = form.getFieldsValue('reason').reason;

	return (
		<Modal
			title={title}
			isModify={isModify}
			className='modal'
			footer={false}
			onCancel={closeForm}
			open={userInfoVisible}
			style={{ top: ' 100px' }}
			isFieldsTouched>
			<Form
				disabled={disabled || loading}
				form={form}
				labelAlign='left'
				layout={layout}
				onFinish={submitForm}
				onValuesChange={handleFormChange}
				onChange={handleChange}
				isFieldsTouched
				{...props}>
				{children}
				<Form.Item
					shouldUpdate
					wrapperCol={{
						span: 24
					}}
					style={{
						margin: 0
					}}>
					{() => {
						if (currentPage === 'request' && isModify) {
							return (
								<Button
									data-testid='form-drawer_submit'
									loading={loading}
									disabled={!isModify}
									type='primary'
									style={{ float: 'right' }}
									onClick={changeToEdit}
									title={t('common.modify')}>
									{getContentSubmitBtn()}
								</Button>
							);
						} else if (currentPage === 'request') {
							return (
								<Button
									data-testid='form-drawer_submit'
									loading={loading}
									disabled={disabled}
									type='primary'
									style={{ float: 'right' }}
									onClick={changeToEdit}
									title={t('common.modify')}>
									{getContentSubmitBtn()}
								</Button>
							);
						} else if (currentPage === 'approve' && approve) {
							return (
								<>
									<Button
										type='primary'
										className='btn-orange'
										style={{ float: 'right', marginLeft: '10px' }}
										onClick={() => actionApproveClick()}
										disabled={false}>
										{t('leader.approved.approved')}
									</Button>
									<Button
										data-testid='form-drawer_submit'
										loading={loading}
										disabled={false}
										type='primary'
										style={{ float: 'right' }}
										onClick={changeToEdit}
										title={t('common.modify')}>
										{getContentSubmitBtn()}
									</Button>
								</>
							);
						} else {
							return (
								<Button
									data-testid='form-drawer_submit'
									loading={loading}
									disabled={false}
									type='primary'
									style={{ float: 'right', marginRight: '10px' }}
									onClick={changeToEdit}
									title={t('common.modify')}>
									{getContentSubmitBtn()}
								</Button>
							);
						}
					}}
				</Form.Item>
			</Form>
		</Modal>
	);
}
