import { appKeys } from '../global/appKeys';
import axiosClient from './axiosClient';

const url = new URL(process.env.REACT_APP_BASE_URL || 'http://google.com');

const rawUrl = url.origin;

export const menuApis = {
	getMenus() {
		return axiosClient.get(`/AUTHORIZATION/menu/app/${appKeys.app}`, {
			baseURL: rawUrl
		});
	}
};
