import { PAGE_HEADER_VALUE } from '../constant';

// const getParentPath = path => {
// 	const item = PAGE_HEADER_VALUE[path];
// 	if (path) {
// 		if (item) {
// 			return item;
// 		} else {
// 			const paths = path.split('/');
// 			paths.shift();
// 			const parentPath = path.slice(0, path.indexOf(paths[paths.length - 1]) - 1);
// 			return getParentPath(parentPath);
// 		}
// 	} else {
// 		return item;
// 	}
// };

export const checkRoles = path => {
	return PAGE_HEADER_VALUE[path] ?? {};
};
