import { createSlice } from '@reduxjs/toolkit';
import { getToken, getUser, setUser } from '../../common/cookies/Cookie';

const authReducer = createSlice({
	name: 'auth',
	initialState: {
		username: getUser()?.username,
		roles: getUser()?.roles,
		isLogin: Boolean(getToken()),
		token: getToken()
	},
	reducers: {
		logout: state => {
			state.isLogin = false;
			state.username = '';
			state.roles = '';
			state.token = '';
		},
		loginSuccess: (state, { payload }) => {
			state.isLogin = true;
			const { username, regexRoles, sessionToken } = payload || {};
			state.username = username;
			state.roles = regexRoles;
			state.token = sessionToken;

			setUser({ username, roles: regexRoles });
		},
		tokenAvailbilities: (state, { payload }) => {
			state.tokenAvail = payload;
		}
	}
});
const { reducer, actions } = authReducer;
export const { logout, loginSuccess, tokenAvailbilities } = actions;
export default reducer;
