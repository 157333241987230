import React, { memo, useCallback, useState } from 'react';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	CloudSyncOutlined,
	ExportOutlined,
	FullscreenExitOutlined,
	FullscreenOutlined,
	LoadingOutlined
} from '@ant-design/icons';
import ToggleCols from './ToggleCols';
import './UtilBar.scss';
import { DATE_FORMAT, ROLE } from '../../../../constant';
import moment from 'moment/moment';
import { DatePicker, message, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userRequestApi } from '../../../../api/userRequestApi';
import { mutate } from 'swr';

const ExportButton = ({ exportFunction }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleExport = useCallback(async () => {
		setLoading(true);
		try {
			const { type, msg } = (await exportFunction()) ?? {
				msg: t('message.exportSuccess'),
				type: 'success'
			};
			message[type](msg);
			setLoading(false);
		} catch (e) {
			console.error('Error:', e);
			setLoading(false);
			message.error(t('message.exportError'));
		}
	}, [exportFunction, t]);

	return typeof exportFunction === 'function' ? (
		loading ? (
			<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
		) : (
			<ExportOutlined className='util-bar__item' onClick={handleExport} />
		)
	) : null;
};

function UtilBar({ handleExport, fullScreen, className = '', cols = [], isSync, setCols = () => {} }) {
	const { t } = useTranslation();
	const { roles } = useSelector(state => state.auth);
	const [syncStatus, setSyncStatus] = useState('');

	const [syncMonth, setSyncMonth] = useState(moment());
	const syncSuccess = () => {
		message.success(t('message.syncSuccess'));
		setSyncStatus(true);
	};
	const syncError = () => {
		message.error(t('message.syncError'));
		setSyncStatus(false);
	};
	const [isShowSpin, setIsShowSpin] = useState(false);

	const handleSync = async () => {
		try {
			setSyncStatus('');
			setIsShowSpin(true);
			const startDate = syncMonth.clone().startOf('month').format(DATE_FORMAT);
			const endDate = syncMonth.clone().endOf('month').format(DATE_FORMAT);
			const syncValue = {
				startDate,
				endDate
			};
			await userRequestApi.sync_timeKeeping(syncValue);
			mutate();
			setIsShowSpin(false);
			syncSuccess();
		} catch (e) {
			setIsShowSpin(false);
			syncError();
		}
	};

	const handleOnChangeSyncMonth = e => {
		setSyncMonth(e);
	};

	return (
		<div className={`util-bar ${className}`}>
			{(roles?.includes(ROLE.COSIC_ADMIN) || roles?.includes(ROLE.COSIC_SUPER_ADMIN)) && isSync === true && (
				<div className='util-bar__sync-data'>
					<div className='icon-sync-status'>
						{isShowSpin && <Spin />}
						{syncStatus === true && (
							<Space>
								<CheckCircleOutlined style={{ color: '#389e0d' }} />
							</Space>
						)}
						{syncStatus === false && (
							<Space>
								<CloseCircleOutlined style={{ color: '#ff4d4f' }} />
							</Space>
						)}
					</div>
					<span>{t('admin.sync-timeKeeping')}</span>
					<DatePicker
						picker='month'
						className='util-bar__sync-data--month-picker'
						value={syncMonth}
						onChange={handleOnChangeSyncMonth}
					/>
					<CloudSyncOutlined className='util-bar__sync-data--sync-icon' onClick={handleSync} />
				</div>
			)}

			<ExportButton className='util-bar__item' exportFunction={handleExport} />
			<ToggleCols setCols={setCols} cols={cols} />
			{fullScreen.active ? (
				<FullscreenExitOutlined className='util-bar__item' onClick={fullScreen.exit} />
			) : (
				<FullscreenOutlined className='util-bar__item' onClick={fullScreen.enter} />
			)}
		</div>
	);
}

export default memo(UtilBar);
