import { gotoLogin } from '../utils/auth';

export function WrongTokenPage() {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
			<h2>Your session token is invalid or expired.</h2>
			<p>Please log in again.</p>
			<button
				style={{
					padding: '10px 20px',
					backgroundColor: '#007BFF',
					color: 'white',
					border: 'none',
					borderRadius: '4px',
					cursor: 'pointer'
				}}
				onClick={() => {
					const currentURL = new URL(window.location.href);
					currentURL.search = ''; // removes URL parameters
					gotoLogin();
				}}>
				Log In
			</button>
		</div>
	);
}
