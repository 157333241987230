import {appKeys} from '../global/appKeys';
import {Roles} from './Roles';
import {intersection} from 'lodash';
// gen keys

export const calculateKey = (parentKey, key) => {
	if (parentKey && key) {
		return `${parentKey}.${key}`;
	}

	if (parentKey || key) {
		return parentKey || key;
	}

	return undefined;
};

export const calculateRouteItems = (routes, parentKey) => {
	const results = [];

	routes?.forEach(route => {
		const { key, children, noGenKey } = route;
		const resultKey = calculateKey(parentKey, key);
		const result = { ...route };
		if (resultKey && !noGenKey) {
			result.key = `${appKeys.app}.${resultKey}`;
		}
		if (children) {
			result.children = calculateRouteItems(children, resultKey);
		}
		results.push(result);
	});

	return results;
};

//Flatten routes
const calculatePath = (parentPath, path) => {
	let temp = undefined;
	if (parentPath && path) {
		temp = `${parentPath}/${path}`;
	} else if (parentPath || path) {
		temp = parentPath || path;
	}

	if (temp) {
		return temp.replace(/\/+/g, '/');
	}
	return undefined;
};

export const flattenRoutes = (roles, routes, parentPath) => {
	const results = [];
	routes.forEach(route => {
		const { children, path, ...rest } = route;
		const resultPath = calculatePath(parentPath, path);

		if (children) {
			results.push({ ...rest, path: resultPath, children });
			const childrenRole = children?.map(child => {
				if (!isAllowedRoute(roles, child)) {
					return false;
				}
				return child;
			});
			const childrenResult = flattenRoutes(roles, childrenRole, resultPath);
			results.push(...childrenResult);
		} else {
			results.push({ ...rest, path: resultPath });
		}
	});
	return results;
};
export const isShowSidebarItem = (userRole, sidebarItem) => {
	const path = sidebarItem?.path;
	if (!path) {
		return sidebarItem;
	}
	const route = Roles.find(item => item?.path === path);

	if (!isAllowedRoute(userRole, route)) {
		return false;
	}
	return sidebarItem;
};
export const isAllowedRoute = (userRole, route) => {
	if (route?.permissionKey === undefined) {
		return true;
	}
	const arrayRole = intersection(userRole, route?.permissionKey);
	return !!arrayRole.length;
};
