export const options = [
	{
		key: 'upload',
		label: 'Tải lên bảng lương'
	},
	{
		key: 'sends',
		label: 'Gửi bảng lương'
	},
	{
		key: 'editPin',
		label: 'Sửa mã pin'
	}
];
