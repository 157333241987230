import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { DATE_FORMAT, MAX_DISPLAY_LEN, TYPE_COLORS } from '../../../constant/common';
import LongText from '../../../components/LongText/LongText';
import { formatNum } from '../../../utils';
import moment from 'moment';

const formatDayInMonth = date => {
	return moment(date, DATE_FORMAT).format('ddd, [ngày] DD');
};

export default function useCreateColumns() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const MaxText = text => <LongText max={MAX_DISPLAY_LEN}>{text}</LongText>;
	return useMemo(() => {
		const columns = [
			{
				title: t('common.day'),
				dataIndex: 'dateAt',
				width: 125,
				align: 'center',
				render: formatDayInMonth
			},
			{
				title: t('common.checkIn'),
				dataIndex: 'checkInTime',
				align: 'center'
			},
			{
				title: t('common.checkOut'),
				dataIndex: 'checkOutTime',
				align: 'center'
			},
			{
				title: t('common.ot'),
				dataIndex: 'otHour',
				render: formatNum,
				align: 'center'
			},
			{
				title: t('time_keeping.workTime'),
				dataIndex: 'workTime',
				render: formatNum,
				align: 'center'
			},
			{
				title: t('common.disconnectTime'),
				dataIndex: 'disconnectTime',
				render: formatNum,
				align: 'center'
			},
			{
				title: t('admin.cong'),
				align: 'center',
				dataIndex: 'wage',
				render: formatNum
			},
			{
				title: t('common.timeLate'),
				dataIndex: 'timeGoLateAndWentEarly',
				render: formatNum,
				align: 'center'
			},
			{
				title: t('common.request'),
				dataIndex: 'request',
				align: 'center',
				render: (request, { offColor }) => {
					return <Tag color={TYPE_COLORS[offColor]}>{request}</Tag>;
				}
			},
			{
				title: t('common.reason'),
				dataIndex: 'reason',
				align: 'center',

				render: MaxText
			}
		];
		return columns;
	}, [t, dispatch]);
}
