import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import i18n from '../translation/i18n';

const { confirm } = Modal;

const showConfirmModal = props => {
	confirm({
		maskClosable: true,
		icon: <ExclamationCircleOutlined />,
		okText: i18n.t('button.ok'),
		cancelText: i18n.t('common.cancel'),
		cancelButtonProps: { 'data-testid': 'confirm-modal_cancel-btn' },
		okButtonProps: { 'data-testid': 'confirm-modal_ok-btn' },
		...props
	});
};

function showErrMsg(error) {
	message.error(error?.response?.data?.message || error?.data?.message || error?.message);
}

export { showConfirmModal, showErrMsg };
