import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../app/reducers/formSlice';
import { useFetch } from '../../../common/hooks';
import ETable from '../../../components/ETable';
import { USER_REQUEST_API_URL } from '../../../constant/common/api';
import FormModal from './components/FormModal/FormModal';
import useColumn from './components/useColumn';
import ButtonAdd from '../../../components/ButtonAdd';
import FilterBar from './components/FilterBar';
import './styles.scss';

export default function RequestUser() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { data, loading, mutate } = useFetch({ url: USER_REQUEST_API_URL });
	const columns = useColumn(mutate);
	const [filteredData, setFilteredData] = useState();

	return (
		<>
			<ETable
				header={
					<div className='e-header'>
						<ButtonAdd handleClick={() => dispatch(openModal())}>{t('request.createRequest')}</ButtonAdd>
						<FilterBar data={data} onChange={setFilteredData} />
					</div>
				}
				dataSource={filteredData ? filteredData : data?.data}
				columns={columns}
				pagination={{
					total: data ? data?.totalElements : null
				}}
				loading={loading}
			/>
			<FormModal mutate={mutate} />
		</>
	);
}
