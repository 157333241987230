import {URL} from '../constant';
import {CONFIG_PDAYS, CONFIG_TIME, HOLIDAYS} from '../constant/common/api';
import axiosClient from './axiosClient';

export const leaderApi = {
	approveRequest(id, payload) {
		const url = `${URL.LEADER_REQUEST_URL}/${id}/accept`;
		return axiosClient.put(url, payload);
	},

	approveMultipleRequest(data) {
		return axiosClient.put(URL.URL_MULTIPLE_APPROVE, data);
	},

	statisticTableApi() {
		const url = `${URL.STATISTICAL_API_URL}`;
		return axiosClient.get(url);
	},
	config_pdays(data) {
		return axiosClient.put(`${CONFIG_PDAYS}`, data);
	},
	get_holidays(params) {
		return axiosClient.get(`${HOLIDAYS}`, {params});
	},
	add_configTime(username, data) {
		return axiosClient.post(`${CONFIG_TIME} /${username}`, data);
	},
	edit_configTime(id, data) {
		return axiosClient.put(`${CONFIG_TIME} /${id}`, data);
	},
	delete_configTime(id) {
		return axiosClient.delete(`${CONFIG_TIME} /${id}`);
	}
};
