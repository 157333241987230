import { useRoutes } from 'react-router-dom';
import { ErrorPage } from '../components/error/Error';
import MainLayout from '../layouts';
import { useUserRoutes } from './useUserRoutes';

export const Routes = () => {
	const userRoutes = useUserRoutes();

	return useRoutes([
		{
			path: '/',
			element: <MainLayout />,
			children: userRoutes?.userRoles
		},
		{
			path: '*',
			element: <ErrorPage />
		}
	]);
};
