import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useFetch, useFilter} from '../../../common/hooks';
import ETable from '../../../components/ETable';
import {WORKTIME} from '../../../constant';
import {ADMIN_LOG_TIME_MEMBER} from '../../../constant/common/api';
import UpdateForm from './UpdateForm';
import useCreateColumns from './useColumns';
import {getWorkingDay} from '../../../utils/dateTime';

export default function PersonalStatistic() {
	const {filter, setFilter} = useFilter();
	const {username} = useSelector(state => state.auth);
	const {t} = useTranslation();

	useEffect(() => {
		if (username) {
			setFilter({
				...filter,
				username
			});
		}
	}, []);
	const { data, loading, mutate } = useFetch({
		url: `${ADMIN_LOG_TIME_MEMBER}/${filter?.username}`,
		requiredParams: ['date']
	});
	const columns = useCreateColumns();
	const userData = data?.data?.[0];

	return (
		<>
			<ETable
				header={
					<div className='header-table'>
						<h3>
							{t('common.total_work')} {userData?.totalWorkDays || 0} / {getWorkingDay(filter?.date)}
						</h3>
						<h3>
							{t('common.totalWageFine')}: {userData?.totalWageFine?.toFixed(1)}
						</h3>
						<h3>
							{t('common.totalTimeLate')}: {userData?.totalTimeGoLateWentEarly?.toFixed(2)}
						</h3>
					</div>
				}
				className='historyTable'
				rowClassName={record => (record.workTime <= WORKTIME ? 'warning' : '')}
				loading={loading}
				columns={columns}
				dataSource={userData?.workDays ?? []}
				pagination={{
					hideOnSinglePage: true,
					pageSize: data?.limit
				}}
				rowKey='dateAt'
			/>
			<UpdateForm mutate={mutate} />
		</>
	);
}
