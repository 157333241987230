import { useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { queryParse, queryStringify } from '../../utils';

export function useFilter() {
	const { search, pathname } = useLocation();
	const [_, setParams] = useSearchParams();

	const filter = useMemo(() => queryParse(search), [search]);

	const setFilter = useCallback(
		(
			params = {},
			options = {
				replace: true
			}
		) => {
			setParams(queryStringify(params), options);
		},
		[pathname]
	);

	return {
		filter,
		search,
		setFilter
	};
}
