import { Tooltip } from 'antd';
import React from 'react';

const LongText = ({ children, max, className }) => {
	if (children?.length > max && typeof children === 'string') {
		return (
			<Tooltip placement='bottomRight' title={children}>
				<p className={className ? className : ''} style={{ margin: 0 }}>
					{`${children.slice(0, max)}...`}
				</p>
			</Tooltip>
		);
	}
	return (
		<p className={className ? className : ''} style={{ margin: 0 }}>
			{children}
		</p>
	);
};

export default LongText;
