import { Button, Form, Modal, Upload } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import commonApi from '../../api/commonApi';
import './styles.scss';
import { useEffect } from 'react';

export const FormImage = ({ currentPatchPhotos, isAdd }) => {
	const { t } = useTranslation();
	const [currentListPhoto, setCurrentListPhoto] = useState([]);
	const [currentListFileName, setCurrentListFileName] = useState([]);

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const form = Form.useFormInstance();
	const { setFieldValue, getFieldValue } = form;
	const currentPhotos = getFieldValue('evidences');
	useEffect(() => {
		if (currentPatchPhotos?.length > 0 && isAdd === false) {
			setCurrentListFileName(_.mapValues(currentPatchPhotos, 'fileName'));
			setCurrentListPhoto(currentPatchPhotos);
		}
	}, [currentPhotos]);

	function beforeUpload(file) {
		const isLt2M = file.size / 1024 / 1024 < 10;
		if (!isLt2M) {
			message.error('Dung lượng file cần nhỏ hơn 20Mb');
		}
		return isLt2M;
	}
	const handlePreview = async file => {
		let src = file.url;
		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	const customPatchPhotos = currentListPhoto?.map((photo, index) => ({
		uid: index.toString(),
		url: `${photo?.url}` || '',
		fileName: `${photo?.fileName}` || ''
	}));
	const acceptType = 'image/png, image/jpeg, image/jpg';
	const handleCancel = () => setPreviewOpen(false);
	return (
		<>
			<Upload
				disabled={false}
				listType='picture-card'
				accept={acceptType}
				beforeUpload={beforeUpload}
				onPreview={handlePreview}
				fileList={customPatchPhotos}
				onRemove={image => {
					setFieldValue('evidences', [...currentPatchPhotos.filter(photo => photo !== image.fileName)]);
					setCurrentListFileName([...currentListFileName.filter(item => item !== image.fileName)]);
					setCurrentListPhoto([...currentListPhoto.filter(item => item.url !== image.url)]);
				}}
				customRequest={async ({ onSuccess, file }) => {
					const { data } = await commonApi.upload(file);
					setFieldValue('evidences', [...currentListFileName, data?.fileName]);
					setCurrentListFileName([...currentListFileName, data?.fileName]);
					setCurrentListPhoto([...currentListPhoto, data]);
					onSuccess?.('ok');
				}}>
				<Button className='btn-upload' type='dashed'>
					{t('common.upload')}
				</Button>
			</Upload>
			<Modal open={previewOpen} title='Xem ảnh' footer={null} onCancel={handleCancel} centered>
				<img alt='example' style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</>
	);
};
