import { Button, Dropdown } from 'antd';
import React from 'react';
import { options } from '../constant';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../app/reducers/formSlice';

const DropDownA = () => {
	const dispatch = useDispatch();
	const handleClick = ({ key }) => {
		dispatch(openModal(key));
	};
	return (
		<Dropdown
			menu={{
				items: options,
				onClick: handleClick
			}}
			trigger={['click']}>
			<Button type='primary'>
				Chức năng <DownOutlined />
			</Button>
		</Dropdown>
	);
};

export default DropDownA;
