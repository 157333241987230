import { DatePicker, Form, Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../../../../components/FormItem';
import { DATE_FORMAT, TIME_OT, TODAY } from '../../../../../../constant';
import { setTimeOT } from '../../../../../../app/reducers/timeSlice';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function OverTimeForm({ request }) {
	const { t } = useTranslation();
	const form = Form.useFormInstance();
	const dispatch = useDispatch();
	const { timeOT } = useSelector(state => state.time);
	const { visible } = useSelector(state => state.form);
	useEffect(() => {
		form.setFields([
			{
				name: 'rangeDate',
				value: [TODAY, TODAY]
			},
			{
				name: 'timeOT',
				value: timeOT
			}
		]);
	}, []);
	return (
		<>
			<FormItem name='rangeDate' label={t('common.day')} rules={[{ required: true, message: t('message.exactlyDay') }]}>
				<RangePicker format={DATE_FORMAT} />
			</FormItem>
			<FormItem name='timeOT' label={t('request.timeOT')} rules={[{ required: true, message: t('message.timeOTRequired') }]}>
				<Select
					onChange={value => {
						dispatch(setTimeOT(value));
					}}>
					{TIME_OT.map(time => {
						return <Option key={time}>{+time.replace(/:.+/, '')}</Option>;
					})}
				</Select>
			</FormItem>
		</>
	);
}
