import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Popconfirm, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axiosClient from '../../../api/axiosClient';
import { openModal } from '../../../app/reducers/formSlice';
import { useFetch } from '../../../common/hooks';
import { setVisibleModal } from '../../../common/reducer';
import UserSelect from '../../../components/CommonFields/UserSelect';
import ETable from '../../../components/ETable';
import ModalForm from '../../../components/Modal';
import { MEMBERS, URL_ADMIN_MAPPED_USERS, URL_MEMBERS_COMPANY } from '../../../constant/common/api';
import i18n from '../../../translation/i18n';

export default function Setting() {
	const { t } = useTranslation();
	const { id } = useSelector(state => state.form);
	const [form] = Form.useForm();

	const dispatch = useDispatch();
	const { visibleModal } = useSelector(state => state.common);

	const [userCompany, setUserCompany] = useState();
	const [userSystems, setUserSystems] = useState();

	const onChangeUserCompany = (_, value) => {
		setUserCompany(value);
	};
	const onChangeUserSystems = (_, value) => {
		setUserSystems(value);
	};
	const showModal = () => {
		console.log(userCompany, userSystems);
		if (userCompany && userSystems) {
			dispatch(setVisibleModal(true));
		}
	};

	const mapped_users = useFetch({ url: URL_ADMIN_MAPPED_USERS });

	const toggleLink = async () => {
		try {
			await axiosClient.post(URL_ADMIN_MAPPED_USERS, {
				username: userSystems?.value,
				userEnrollNumber: userCompany?.value
			});
			message.success(i18n.t('message.linkSuccess'));
			mapped_users.mutate();
			dispatch(setVisibleModal(false));
		} catch (e) {
			message.error(e.response.data.message);
			dispatch(setVisibleModal(false));
		}
	};

	const confirmDelete = async value => {
		try {
			await axiosClient.delete(`${URL_ADMIN_MAPPED_USERS}/${value?.username}`);
			mapped_users.mutate();
			message.success(i18n.t('message.deleteSuccess'));
		} catch (e) {
			message.error(e);
		}
	};

	const confirmUpdate = async value => {
		await Promise.resolve();
		try {
			await axiosClient.put(`${URL_ADMIN_MAPPED_USERS}/${id}`, { userEnrollNumber: value.mccUserFullName });
			mapped_users.mutate();
		} catch (e) {
			message.error(e);
		}
	};

	const column = [
		{
			title: i18n.t('common.number_order'),
			width: 60,
			align: 'center',
			render: (_, _record, index) => {
				return (mapped_users?.data?.currentPage - 1) * mapped_users?.data?.limit + index + 1;
			}
		},
		{
			title: i18n.t('common.userName_systems'),
			dataIndex: 'sysUserFullName',
			render: (value, record) => {
				return `${record.username} - ${value}`;
			}
		},
		{
			title: i18n.t('common.userName_timekeeper'),
			dataIndex: 'mccUserFullName'
		},
		{
			title: 'Tác vụ',
			align: 'center',
			width: 200,
			dataIndex: 'username',
			render: (username, record) => {
				return (
					<Space className='site-button-ghost-wrapper' wrap>
						<Popconfirm
							title={i18n.t('common.delete_linked')}
							icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
							onConfirm={(_, _record) => confirmDelete(record)}
							okText={i18n.t('common.ok')}
							cancelText={i18n.t('common.no')}>
							<Button danger>{t('common.delete')}</Button>
						</Popconfirm>

						<>
							<Button
								type='primary'
								onClick={() => {
									dispatch(openModal(username));
									form.setFields([
										{
											name: 'mccUserFullName',
											value: record.mccUserFullName
										},
										{
											name: 'sysUserFullName',
											value: record.sysUserFullName
										}
									]);
								}}>
								{t('common.modify')}
							</Button>
						</>
					</Space>
				);
			}
		}
	];

	return (
		<div className='e-table'>
			<div className='setting_dropdown'>
				<UserSelect
					keyName='username'
					// search={true}
					placeHolder={i18n.t('common.userName_systems')}
					url={MEMBERS}
					onChange={onChangeUserSystems}
					className='userName_systems'
				/>
				<UserSelect
					keyName='username'
					// search={true}
					placeHolder={i18n.t('common.userName_company')}
					url={URL_MEMBERS_COMPANY}
					onChange={onChangeUserCompany}
					className='userName_company'
					titleKey='userFullName'
					valueKey='userEnrollNumber'
				/>
				<Button className='add-link-users' type='primary' onClick={showModal}>
					{t('common.setup_link')}
				</Button>
				<Modal
					title={i18n.t('common.linked_user_name')}
					open={visibleModal}
					onOk={toggleLink}
					onCancel={() => dispatch(setVisibleModal(false))}>
					{i18n.t('link', {
						val1: `${userSystems?.value} - ${userSystems?.children}`,
						val2: userCompany?.children
					})}{' '}
				</Modal>
			</div>
			<div className='table-mapper-users' style={{ marginTop: '-4rem' }}>
				{mapped_users && (
					<ETable
						rowKey='username'
						columns={column}
						dataSource={mapped_users.data?.data}
						pagination={{
							total: mapped_users?.data?.totalElements
						}}
					/>
				)}
			</div>
			<ModalForm form={form} onSubmit={confirmUpdate} title={i18n.t('common.update_infor')} isAdd={false} isModified>
				<Form.Item label={i18n.t('common.userName_systems')} name='sysUserFullName' required={true}>
					<UserSelect
						placeHolder={i18n.t('common.userName_systems')}
						url={MEMBERS}
						onChange={onChangeUserSystems}
						className='userName_systems'
						disabled
					/>
				</Form.Item>
				<Form.Item label={i18n.t('common.userName_timekeeper')} name='mccUserFullName' required={true}>
					<UserSelect
						placeHolder={i18n.t('common.userName_company')}
						url={URL_MEMBERS_COMPANY}
						onChange={onChangeUserCompany}
						className='userName_company'
						titleKey='userFullName'
						valueKey='userEnrollNumber'
						style={{ marginLeft: '0px' }}
					/>
				</Form.Item>
			</ModalForm>
		</div>
	);
}
