import {Button} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {openHistoriesModal} from '../../../app/reducers/formSlice';

export default function useCreateColumns() {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const targetType = [
		{
			text: 'Yêu cầu',
			value: 'request'
		},
		{
			text: 'Chấm công',
			value: 'time_keeping'
		}
	];

	return useMemo(() => {
		const columns = [
			{
				title: t('admin.edit_time'),
				dataIndex: 'updateAt',
				sorter: true,
				width: '10%',
				align: 'center',
				render: time => <span className='workDate'>{time}</span>
			},
			{
				title: t('admin.id'),
				dataIndex: 'targetID',
				width: '8%',
				align: 'center',
				render: id => (
					<Button
						className='btn-orange'
						onClick={() => {
							dispatch(openHistoriesModal(id));
						}}
					>
						{id}
					</Button>
				)
			},
			{
				title: t('admin.update_by'),
				dataIndex: 'updateBy',
				width: '10%',
				align: 'center'
			},
			{
				title: t('admin.object_type'),
				dataIndex: 'targetType',
				width: '10%',
				align: 'center',
				filters: targetType
			},

			{
				title: t('admin.init_data'),
				dataIndex: 'originalValue',
				width: '20%',
				align: 'center',
				render: content => (
					<div style={{whiteSpace: 'pre'}}>
						<p>{content}</p>
					</div>
				)
			},

			{
				title: t('admin.current_data'),
				dataIndex: 'updatedValue',
				width: '20%',
				align: 'center',
				render: content => (
					<div style={{whiteSpace: 'pre'}}>
						<p>{content}</p>
					</div>
				)
			}
		];
		return columns;
	}, [t]);
}
