import React, { useState, useEffect } from 'react';
import { DatePicker, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import UserSelect from '../../../../components/CommonFields/UserSelect';
import { ACRONYM_TYPE } from '../../../../constant/common/api';
import { DATE_FORMAT } from '../../../../constant';
import { REVIEWED, REJECT, CANCELED, CANCEL, ACCEPT, PENDING } from '../../../../constant/common';
import moment from 'moment';
import '../styles.scss';

export default function FilterForm({ data, onChange }) {
	const { t } = useTranslation();
	const { RangePicker } = DatePicker;
	const [time, setTime] = useState();
	const [offTypeAcronym, setOffTypeAcronym] = useState();
	const [status, setStatus] = useState();

	const handleTimeChange = values => {
		setTime(values);
		filterData();
	};

	const handleOffTypeAcronymChange = value => {
		setOffTypeAcronym(value);
		filterData();
	};

	const handleStatus = value => {
		setStatus(value);
		filterData();
	};

	const filterData = () => {
		const filteredData = data?.data.filter(item => {
			let isValid = true;
			if (time) {
				const dateStart = moment(time?.[0]._d, DATE_FORMAT);
				const dateEnd = moment(time?.[1]._d, DATE_FORMAT);
				const itemStartDate = moment(item.startDate, DATE_FORMAT);
				const itemEndDate = moment(item.endDate, DATE_FORMAT);
				if (item.monthAndYearForRequestCP) {
					const monthAndYearForRequestCP = moment(item.monthAndYearForRequestCP, DATE_FORMAT);
					isValid = isValid && monthAndYearForRequestCP.isSameOrAfter(dateStart) && monthAndYearForRequestCP.isSameOrBefore(dateEnd);
				} else {
					isValid = isValid && itemStartDate.isSameOrAfter(dateStart) && itemEndDate.isSameOrBefore(dateEnd);
				}
			}
			if (offTypeAcronym) {
				isValid = isValid && item.offTypeAcronym === offTypeAcronym;
			}
			if (status) {
				isValid = isValid && item.status === status;
			}
			return isValid;
		});
		onChange(filteredData);
	};

	useEffect(() => {
		filterData();
	}, [time, offTypeAcronym, status]);

	return (
		<div className='filter-bar'>
			<Form layout='inline'>
				<Form.Item name='time'>
					<RangePicker keyName='time' valueKey='time' format={DATE_FORMAT} onChange={handleTimeChange} />
				</Form.Item>
				<Form.Item name='offTypeAcronym'>
					<UserSelect
						search={false}
						keyName='offTypeAcronym'
						placeHolder={t('common.search_request')}
						url={ACRONYM_TYPE}
						titleKey='name'
						valueKey='acronym'
						onChange={handleOffTypeAcronymChange}
					/>
				</Form.Item>
				<Form.Item name='status'>
					<UserSelect
						search={false}
						keyName='status'
						placeHolder={t('common.search_status')}
						options={[
							{ name: ACCEPT, value: 'Đã duyệt' },
							{ name: CANCEL, value: 'Đã hủy' },
							{ name: REVIEWED, value: 'Đã xác nhận' },
							{ name: PENDING, value: 'Chờ duyệt' },
							{ name: CANCELED, value: 'Huỷ bỏ' },
							{ name: REJECT, value: 'Từ chối' }
						]}
						titleKey='name'
						valueKey='value'
						onChange={handleStatus}
					/>
				</Form.Item>
			</Form>
		</div>
	);
}
