import React, { useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constant';
import StatisticCardGroup from './StatisticCardGroup';
import { useFilter } from '../../../common/hooks';

export default function Statistic() {
	const { filter, setFilter } = useFilter();
	const handleChangeDate = value => {
		setFilter({ ...filter, date: value ? moment(value).format(DATE_FORMAT) : moment().format(DATE_FORMAT) });
	};

	useEffect(() => {
		setFilter({ ...filter, date: moment().format(DATE_FORMAT) });
	}, []);

	return (
		<div className='statistic'>
			<div className='statistic__time'>
				<p>Thời gian</p>
				<DatePicker value={moment(filter?.date, DATE_FORMAT)} format={DATE_FORMAT} onChange={handleChangeDate} />
			</div>
			<StatisticCardGroup />
		</div>
	);
}
