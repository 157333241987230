export const LEADER_REQUEST_URL = '/leader/requests';
export const TIMEKEEPING_API_URL = '/log-time/members';
export const ADMIN_LOG_TIME_MEMBER = '/admin/log-time/members';
export const ADMIN_LOG_TIME = '/admin/log-time';
export const ADMIN_TIME_KEEPER = '/admin/timekeeper';
export const ADMIN_WAGE_CHANGE = 'admin/wage-changed';
export const ADMIN_WORKDAYS = '/admin/log-time/work-days';
export const ADMIN_HISTORY = '/histories';
export const ADMIN_SYSTEM_CONFIG = '/admin/regulation';
export const SYSTEM_CONFIG = '/regulation';
export const USER_REQUEST_API_URL = '/requests';
export const ACRONYM_TYPE = '/off-types';
export const MEMBERS = '/members';
export const STATISTICAL_API_URL = '/leader/statistical';
export const URL_ACCEPTED_BY = '/members';
export const URL_MEMBERS_COMPANY = '/admin/timekeeper/users';
export const URL_ADMIN_MAPPED_USERS = 'admin/system/mapped-users';
export const URL_ADMIN_EXPORT_GENERAL = 'admin/export';
export const URL_ADMIN_EXPORT_TIMEKEEPING = 'admin/export/timesheets';
export const GET_USERS_SETTING = 'admin/setting/members';
export const UPLOAD_FILE = 'file';
export const ADMIN_USERS = 'admin/members';
export const DAY_OFF = '/member/pdays';
export const USER_INFO = '/member';
export const CONFIG_PDAYS = '/admin/config/pdays';
export const HOLIDAYS = '/admin/holidays';
export const CONFIG_TIME = '/admin/config';
export const URL_UPLOAD_ACCOUNTANT_EXCEL = 'accountant/upload';
export const URL_UPLOAD_CONFIRM = 'accountant/upload/save';
export const URL_ACCOUNTANT_MEMBERS = 'accountant/members';
export const URL_EXPORT_ACCOUNTANT = 'accountant/export-excels';
export const URL_EXPORT_SAMPLE = 'accountant/export-sample';
export const URL_SEND_EMAILS = '/accountant/send-emails';
export const URL_PIN_CODE = 'pin-code/';

export const URL_MULTIPLE_APPROVE = '/leader/requests/accept-multiple';
