import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch, useFilter } from '../../../common/hooks';
import ETable from '../../../components/ETable';
import { ADMIN_WORKDAYS, URL_ADMIN_EXPORT_GENERAL } from '../../../constant/common/api';
import useCreateColumns from './useColumnStatistic';
import { getWorkingDay } from '../../../utils/dateTime';
import useExportExcel from '../useExportExcel';

export default function GeneralStatistic() {
	const columns = useCreateColumns();
	const { t } = useTranslation();
	const { filter } = useFilter();
	const { data, loading } = useFetch({
		url: ADMIN_WORKDAYS
	});
	const handleExport = useExportExcel(URL_ADMIN_EXPORT_GENERAL, filter?.date, t('export.general'));

	return (
		<ETable
			header={
				<div style={{ display: 'flex' }}>
					<h3>{t('admin.totalWorkInMonth')}</h3>
					<h3 style={{ paddingLeft: '15px' }}>{getWorkingDay(filter?.date)}</h3>
				</div>
			}
			loading={loading}
			columns={columns}
			dataSource={data?.data}
			pagination={{
				total: data?.totalElements
			}}
			sticky
			rowKey='username'
			handleExport={handleExport}
		/>
	);
}
