import {
	ADMIN_SYSTEM_CONFIG,
	ADMIN_TIME_KEEPER,
	ADMIN_USERS,
	GET_USERS_SETTING,
	LEADER_REQUEST_URL,
	USER_INFO,
	USER_REQUEST_API_URL
} from '../constant/common/api';
import axiosClient from './axiosClient';

const convertToFormDataForAvatar = objectData => {
	const formData = new FormData();

	Object.keys(objectData).forEach(key => {
		if (key === 'avatar') {
			const item = objectData[key];
			Object.keys(item).forEach(key => {
				formData.append(`avatar.${key}`, item[key]);
			});
		} else {
			formData.append(key, objectData[key]);
		}
	});

	return formData;
};

export const userRequestApi = {
	delete(id) {
		return axiosClient.delete(`${USER_REQUEST_API_URL}/${id}`);
	},
	postRequest(payload) {
		// const formData = convertToFormData(objectData);

		return axiosClient.post(`${USER_REQUEST_API_URL}`, payload);
	},

	// edit with styles evidences[0].name, evidences[1].name, evidences[2].file
	editById(id, payload) {
		return axiosClient.put(`${USER_REQUEST_API_URL}/${id}`, payload);
	},
	getById(id) {
		return axiosClient.get(`${USER_REQUEST_API_URL}/${id}`);
	},
	editUserSetting(id, data) {
		return axiosClient.put(`${GET_USERS_SETTING}/${id}`, data);
	},
	cancelRequest(id) {
		return axiosClient.put(`${LEADER_REQUEST_URL}/${id}/accept`, {
			approveType: 'CANCEL'
		});
	},
	deleteUsers(id) {
		return axiosClient.delete(`${ADMIN_USERS}/${id}`);
	},
	edit_infoUser(data) {
		const formData = convertToFormDataForAvatar(data, 'avatar');
		return axiosClient.put(USER_INFO, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	sync_timeKeeping(data) {
		return axiosClient.post(ADMIN_TIME_KEEPER, data);
	},
	update_regulations(data) {
		return axiosClient.post(ADMIN_SYSTEM_CONFIG, data);
	}
};
