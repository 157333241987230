import {
	AppstoreOutlined,
	AuditOutlined,
	FileTextOutlined,
	LineChartOutlined,
	MoneyCollectOutlined,
	PullRequestOutlined,
	SettingOutlined
} from '@ant-design/icons';
import { ROLE, displayLeaderApprove, displayReview, displaySuperAdmin } from '../constant';
import { appKeys } from '../global/appKeys';
export const SidebarConfig = [
	{ index: true, path: 'time_keeping', icon: <AppstoreOutlined />, key: appKeys.timeKeeping },
	{ path: 'request', key: appKeys.request, icon: <PullRequestOutlined /> },
	{
		path: 'approve',
		key: appKeys.approve,
		permissionKey: displayLeaderApprove,
		icon: <AuditOutlined />
	},
	{
		path: 'leader_statistic',
		key: appKeys.leaderStatistic,
		permissionKey: [ROLE.COSIC_LEADER],
		icon: <LineChartOutlined />
	},
	{
		path: 'history',
		key: appKeys.history,
		icon: <AuditOutlined />
	},
	{
		path: 'statistic',
		key: appKeys.statistic,
		icon: <LineChartOutlined />,
		permissionKey: displayReview,
		children: [
			{
				path: 'timekeeping_table',
				permissionKey: displayReview,
				key: appKeys.timeKeepingTable
			},
			{
				path: 'general_statistic',
				permissionKey: displayReview,
				key: appKeys.generalStatistic
			},
			{
				path: 'personal_statistic',
				permissionKey: displayReview,
				key: appKeys.personalStatistic
				// children: [
				// 	{
				// 		index: true,
				// 		permissionKey: displayReview
				// 	},
				// 	{
				// 		path: ':username',
				// 		permissionKey: [ROLE.COSIC_LEADER],
				// 		key: appKeys.detail
				// 	}
				// ]
			}
		]
	},

	{
		path: 'setting',
		key: appKeys.setting,
		icon: <SettingOutlined />,
		permissionKey: displaySuperAdmin,
		children: [
			{
				path: 'setup_user',
				permissionKey: displaySuperAdmin,
				key: appKeys.settingUser
			},
			{
				path: 'setup_link',
				permissionKey: displaySuperAdmin,
				key: appKeys.settingLink
			},
			{
				path: 'config_time',
				permissionKey: displaySuperAdmin,
				key: appKeys.settingConfigTime
			},
			{
				path: 'config_leaveMode',
				permissionKey: displaySuperAdmin,
				key: appKeys.settingConfigLeaveMode
			}
		]
	},
	{
		path: 'regulation_leave',
		icon: <FileTextOutlined />,
		key: appKeys.regulationLeave
	},
	{
		path: 'accountant',
		icon: <MoneyCollectOutlined />,
		key: appKeys.accountant,
		permissionKey: [ROLE.COSIC_ACCOUNTANT]
	}
];
