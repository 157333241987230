import queryString from 'query-string';

export const queryStringify = filter => {
	return queryString.stringify(filter, {
		skipNull: true,
		skipEmptyString: true
	});
};

export const queryParse = string => {
	if (typeof string === 'string') {
		return queryString.parse(string, {
			parseBooleans: true,
			parseNumbers: true
		});
	} else {
		return {};
	}
};
