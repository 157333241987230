import { Table } from 'antd';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useFilter } from '../../common/hooks';
import { ASC, ASCEND, DESC } from '../../constant';
import UtilBar from './components/UtilBar';
import './ETable.scss';

function ETable({
	columns = [],
	dataSource = [],
	rowKey,
	header = null,
	scroll = {},
	rowClassName = null,
	onChange,
	pagination = {},
	loading,
	isSync,
	isShowUtilBar = true,
	handleExport,
	...props
}) {
	const handleFullScreen = useFullScreenHandle();
	const [cols, setCols] = useState([]);
	const { filter, setFilter } = useFilter();

	useEffect(() => {
		if (Array.isArray(columns)) {
			setCols(columns.map(col => ({ ...col, show: true })));
		}
	}, [columns]);
	const showCols = useMemo(() => cols.filter(col => col.show), [cols]);

	return (
		<FullScreen className='e-table__fullscreen' handle={handleFullScreen}>
			<div className='e-table'>
				<div className='e-table__header'>
					{header}
					{isShowUtilBar && (
						<UtilBar
							setCols={setCols}
							cols={cols}
							className='e-table__util-bar'
							fullScreen={handleFullScreen}
							isSync={isSync}
							handleExport={handleExport}
						/>
					)}
				</div>
				<Table
					columns={showCols}
					dataSource={dataSource}
					rowKey={rowKey || 'id'}
					setCols={setCols}
					scroll={{
						x: '80vw',
						y: '60vh',
						...scroll
					}}
					rowClassName={rowClassName}
					sticky
					onChange={(pagination, filterValues, sorter) => {
						const newFilter = {
							...filter,
							...filterValues,
							currentPage: pagination.current,
							limit: pagination.pageSize
						};
						if (sorter.field && sorter.order) {
							newFilter.sort = `${sorter.field}-${sorter.order === ASCEND ? ASC : DESC}`;
						} else {
							newFilter.sort = undefined;
						}
						setFilter(newFilter);
					}}
					pagination={{
						showSizeChanger: true,
						showQuickJumper: true,
						pageSize: filter?.limit || 10,
						current: filter?.currentPage || 1,
						...pagination
					}}
					loading={loading}
					{...props}
				/>
			</div>
		</FullScreen>
	);
}

export default memo(ETable);
