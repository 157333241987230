import axios from 'axios';
import { AUTH_BEARER_TOKEN_KEY, SESSION_TOKEN_KEY } from '../constant';

const url = new URL(process.env.REACT_APP_BASE_URL);

export const authApi = {
	verifyToken(token) {
		return axios.get(`${process.env.REACT_APP_AUTH_URL}/verifySessionToken`, {
			params: {
				[SESSION_TOKEN_KEY]: token
			}
		});
	},
	getAccessToken(token) {
		return axios.post(
			`${url.origin}/AUTHORIZATION/api/v1/hiro/company/3d93337b-e50a-4c36-ae2f-6875d5878dda/access-token`,
			undefined,
			{
				headers: {
					[AUTH_BEARER_TOKEN_KEY]: token,
					'Content-Type': 'application/json'
				}
			}
		);
	},
	updateUserInfo(payload, token) {
		const url = `${process.env.REACT_APP_BASE_URL}/member/update`;
		return axios.put(url, payload, { headers: { [AUTH_BEARER_TOKEN_KEY]: token } });
	}
};
