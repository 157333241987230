import { Form, message, Modal } from 'antd';
import React from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { accountantApi } from '../../../api/acountantApi';
import { closeModal, openModal } from '../../../app/reducers/formSlice';

const CreatePin = ({ mutate }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { visible, id } = useSelector(state => state.form);
	const onSubmit = async value => {
		const domain = id === 'createPin' ? 'create' : 'change';
		const mess = id === 'createPin' ? 'Tạo' : 'Sửa';
		try {
			await accountantApi.verifyCode(domain, value);
			dispatch(closeModal());
			message.success(`${mess} thành công!`);
			mutate?.();
		} catch (e) {
			message.error(e.response.data.message);
			e.response.status === 407 && dispatch(openModal('createPin'));
		}
	};

	const handleCancel = () => {
		dispatch(closeModal());
	};
	const validateConfirmPassword = (base, value) => {
		const password = form.getFieldValue(base);
		const confirm = form.getFieldValue(value);
		if (confirm && confirm !== password) {
			return Promise.reject(new Error('Mã PIN không đúng'));
		}
		return Promise.resolve();
	};
	const render = () => {
		if (id === 'createPin') {
			return (
				<>
					<Form.Item
						label='Nhâp mã Pin'
						name='pinCode'
						rules={[
							{
								required: true,
								message: 'Vui lòng nhập mã PIN!'
							}
						]}>
						<ReactCodeInput type='password' fields={6} />
					</Form.Item>
					<Form.Item
						label='Nhập lại mã PIN'
						name='confirmPinCode'
						dependencies={['pinCode']}
						rules={[
							{
								required: true,
								message: 'Vui lòng nhập mã PIN!'
							},
							{
								validator: () => validateConfirmPassword('pinCode', 'confirmPinCode')
							}
						]}>
						<ReactCodeInput type='password' fields={6} />
					</Form.Item>
				</>
			);
		} else if (id === 'editPin') {
			return (
				<>
					<Form.Item
						label='Nhâp mã PIN cũ'
						name='oldPin'
						rules={[
							{
								required: true,
								message: 'Vui lòng nhập mã PIN cũ'
							}
						]}>
						<ReactCodeInput type='password' fields={6} />
					</Form.Item>
					<Form.Item
						label='Nhập lại mã PIN mới'
						name='newPin'
						rules={[
							{
								required: true,
								message: 'Vui lòng nhập mã PIN mới'
							}
						]}>
						<ReactCodeInput type='password' fields={6} />
					</Form.Item>
					<Form.Item
						label='Nhập lại mã PIN mới'
						name='confirmNewPin'
						rules={[
							{
								required: true,
								message: 'Vui lòng nhập mã PIN mới'
							},
							{
								validator: () => validateConfirmPassword('newPin', 'confirmNewPin')
							}
						]}>
						<ReactCodeInput type='password' fields={6} />
					</Form.Item>
				</>
			);
		}
	};
	return (
		<Modal
			centered
			width={400}
			title={id === 'createPin' ? 'Tạo mã PIN' : 'Sửa mã PIN'}
			open={visible}
			onCancel={handleCancel}
			onOk={form.submit}
			icon
			okText='Xác Thực'
			okType='primary'>
			<Form form={form} onFinish={onSubmit} layout='vertical'>
				<div className='modal-input'>{render()}</div>
			</Form>
		</Modal>
	);
};

export default CreatePin;
