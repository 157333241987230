import React from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../common/hooks';
import ETable from '../../../components/ETable';
import {FilterSelect} from '../../../components/FilterComponents/SelectFilter';
import {GET_USERS_SETTING, MEMBERS} from '../../../constant/common/api';
import UserFormModal from './UserFormModal';
import './style.scss';
import useCreateColumns from './userColumns';

export default function SetupUser({isUserInfo}) {
	const {t} = useTranslation();
	const {data, loading, mutate} = useFetch({url: `${GET_USERS_SETTING}`});
	console.log(mutate);

	const columns = useCreateColumns({mutate});

	return (
		<>
			<div className='filter-header'>
				<FilterSelect
					showSearch
					className='select-filter'
					placeHolder={t('common.search_member')}
					url={MEMBERS}
					displayKey='fullName'
					valueKey='username'
				/>
			</div>
			<ETable
				loading={loading}
				columns={columns}
				dataSource={data?.data}
				pagination={{
					total: data?.totalElements
				}}
				sticky
				rowKey='username'
			/>
			{!isUserInfo && <UserFormModal mutate={mutate} />}
		</>
	);
}
