import { Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FormItem({ children, name, required, label, min, type, style, className, rules, form, ...rest }) {
	const props = { name, style, className };
	const lowerLabel = typeof label === 'string' ? label.toLowerCase() : 'field';
	const { t } = useTranslation();
	const rulesFormItem = rules ? [...rules] : [];
	if (required) {
		const placeholderText = type === 'select' ? t('common.select') : t('common.input');
		const message = t('message.pleaseInputText', {
			val: `${placeholderText} ${lowerLabel}`
		});

		const obj = {
			required: true,
			message
		};

		if (type !== 'select') {
			obj.whitespace = true;
		}
		rulesFormItem.push(obj);
	}

	if (min) {
		rulesFormItem.push({
			validator(_, value) {
				value = value?.trim();
				if (value !== '' && value?.length < min) {
					return Promise.reject(new Error(t('message.regexMinChars', { val: `${min}` })));
				}
				return Promise.resolve();
			}
		});
	}
	return (
		<Form.Item {...props} rules={rulesFormItem} shouldUpdate label={label} {...rest}>
			{children}
		</Form.Item>
	);
}
