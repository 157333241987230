import { configureStore } from '@reduxjs/toolkit';
import commonReducers from '../common/reducer';
import formSlice from './reducers/formSlice';
import authReducer from './reducers/authSlice';
import timeSlice from './reducers/timeSlice';
export const store = configureStore({
	reducer: {
		common: commonReducers,
		auth: authReducer,
		form: formSlice,
		time: timeSlice
	}
});
