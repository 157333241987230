import {Form, Input, Select} from 'antd';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {userRequestApi} from '../../../../../api/userRequestApi';
import {setRecord, setRequestType} from '../../../../../app/reducers/formSlice';
import {useFetch} from '../../../../../common/hooks';
import UserSelect from '../../../../../components/CommonFields/UserSelect';
import {FormImage} from '../../../../../components/FormImage/FormImage';
import FormItem from '../../../../../components/FormItem';
import ModalForm from '../../../../../components/Modal';
import {DATE_FORMAT, FORMAT_HH_MM, FORMAT_MONTH, PENDING, REQUESTS_TYPE} from '../../../../../constant';
import {ACRONYM_TYPE, URL_ACCEPTED_BY} from '../../../../../constant/common/api';
import CheckInForm from './ContentForm/CheckInForm';
import LateWork from './ContentForm/LateWork';
import OverTimeForm from './ContentForm/OverTimeForm';
import RemoteForm from './ContentForm/RemoteForm';
import VacationSalary from './ContentForm/VacationSalary';
import './formModal.scss';
import ReparationForm from './ContentForm/ReparationForm';

const {Option} = Select;
const {TextArea} = Input;

function FormModal({ mutate, record, setFormType, moreButtons, isEditHistories, width, isModified, disabled }) {
	const {t} = useTranslation();
	const {id, visible, requestType, record: item, isModify} = useSelector(state => state.form);
	const {username} = useSelector(state => state.auth);
	const { timeOT } = useSelector(state => state.time);
	const [uploadData, setUploadData] = useState([]);
	const [loading, setLoading] = useState(false);

	const timeOT1 = timeOT;
	const [form] = Form.useForm();
	const isAdd = typeof id !== 'number';
	const dispatch = useDispatch();
	const { data } = useFetch({ url: `${URL_ACCEPTED_BY}/${username}/leaders` });
	const { data: allTypes } = useFetch({ url: ACRONYM_TYPE });
	const { pathname } = useLocation();
	const currentPageFormModal = pathname.slice(1);

	const types = useMemo(() => {
		return allTypes?.filter?.((_, index) => index);
	}, [allTypes]);

	useEffect(() => {
		form.setFields([
			{
				name: 'fullName',
				value: data?.fullName
			},
			{
				name: 'offTypeAcronym',
				value: requestType
			},
			{
				name: 'acceptedBy',
				value: data?.userName
			},
			{
				name: 'notiToPerson',
				value: data?.userName
			}
		]);
	}, [visible, id]);

	useEffect(() => {
		const initValue = async () => {
			setLoading(true);
			const data = await userRequestApi.getById(id).catch();
			dispatch(setRecord(data?.data));
			setUploadData(data?.data.evidences);
			dispatch(setRequestType(data?.data.offTypeAcronym));
			if (data?.data) {
				setLoading(false);
				const keys = [
					'startDate',
					'endDate',
					'timeCheck',
					'timeOT',
					'isHalfDay',
					'reason',
					'changeReason',
					'comment',
					'isMorning',
					'offTypeAcronym',
					'acceptedBy',
					'notiToPerson',
					'approveType',
					'evidences',
					'fullName',
					'reviewer'
				];
				const fields = [];
				for (const key of keys) {
					fields.push({
						name: key,
						value: data?.data[key]
					});
				}
				if (data?.data?.offTypeAcronym === REQUESTS_TYPE.OT) {
					fields.push(
						{
							name: 'timeOT',
							value: data?.data?.timeOT
						},
						{
							name: 'startDate',
							value: moment(data?.data?.startDate, DATE_FORMAT)
						},
						{
							name: 'endDate',
							value: moment(data?.data?.endDate, DATE_FORMAT)
						},
						{
							name: 'rangeDate',
							value: [moment(data?.data?.startDate, DATE_FORMAT), moment(data?.data?.endDate, DATE_FORMAT)]
						}
					);
				} else if (data?.data?.offTypeAcronym === REQUESTS_TYPE.CP) {
					fields.push(
						{
							name: 'convertPdaysToWageFine',
							value: data?.data?.convertPdaysToWageFine
						},
						{
							name: 'monthAndYearForRequestCP',
							value: moment(data?.data?.monthAndYearForRequestCP, DATE_FORMAT)
						}
					);
				} else {
					fields.push(
						{
							name: 'timeCheck',
							value: moment(data?.data?.timeCheck, FORMAT_HH_MM)
						},
						{
							name: 'timeOT',
							value: data?.data?.timeOT
						},
						{
							name: 'startDate',
							value: moment(data?.data?.startDate, DATE_FORMAT)
						},
						{
							name: 'endDate',
							value: moment(data?.data?.endDate, DATE_FORMAT)
						},
						{
							name: 'rangeDate',
							value: [moment(data?.data?.startDate, DATE_FORMAT), moment(data?.data?.endDate, DATE_FORMAT)]
						}
					);
				}

				form.setFields(fields);
			} else {
				setLoading(false);
			}
		};
		if (visible && !isAdd) {
			initValue();
		}
	}, [isAdd, visible, id, form]);

	const handleOnchange = value => {
		form.resetFields(['time']);
		dispatch(setRequestType(value));
	};

	function removeUndefinedKeys(obj) {
		const newObject = {};
		for (const key in obj) {
			if (obj[key] !== undefined && obj[key] !== null) {
				newObject[key] = obj[key];
			}
		}
		return newObject;
	}

	const onSubmit = async value => {
		value = removeUndefinedKeys(value);

		const { timeCheck, timeOT, startDate, endDate, rangeDate, convertPdaysToWageFine, monthAndYearForRequestCP, ...restValues } =
			value;
		const sendTime = moment(timeCheck);
		const sendStartDate = rangeDate?.[0] ? rangeDate[0].format(DATE_FORMAT) : startDate?.format(DATE_FORMAT);
		const newValueRequest = {
			...restValues,
			timeOT: timeOT1,
			startDate: rangeDate?.[0] ? rangeDate[0].format(DATE_FORMAT) : startDate?.format(DATE_FORMAT),
			endDate: rangeDate?.[1] ? rangeDate[1].format(DATE_FORMAT) : endDate?.format(DATE_FORMAT) ?? sendStartDate,
			time: moment().format(FORMAT_HH_MM),
			convertPdaysToWageFine: convertPdaysToWageFine && Number(convertPdaysToWageFine),
			monthAndYearForRequestCP: monthAndYearForRequestCP && `11/${monthAndYearForRequestCP.format(FORMAT_MONTH)}`
		};
		if (
			requestType === REQUESTS_TYPE.checkin ||
			requestType === REQUESTS_TYPE.checkout ||
			requestType === REQUESTS_TYPE.late ||
			requestType === REQUESTS_TYPE.early
		) {
			newValueRequest.timeCheck = sendTime.isValid() ? sendTime.format(FORMAT_HH_MM) : timeCheck;
		}

		if (isAdd) {
			await userRequestApi.postRequest(newValueRequest);
		} else {
			await userRequestApi.editById(id, newValueRequest);
		}
		form.resetFields();
		mutate({ ...newValueRequest });
	};

	return (
		<ModalForm
			isPending={item?.status === PENDING}
			width={width}
			isEditHistories={isEditHistories}
			currentPageFormModal={currentPageFormModal}
			form={form}
			onSubmit={onSubmit}
			isAdd={isAdd}
			disabledFeature={true}
			disabled={disabled || !(isModify || isAdd)}
			approve={!isAdd}
			record={record}
			setFormType={setFormType}
			title={isAdd ? t('common.request') : t('common.detail')}
			moreButtons={moreButtons}
			isModified={isModified}>
			<div className='modal-content-input'>
				{currentPageFormModal === 'approve' && (
					<FormItem name='fullName' label={t('user.fullName')} type='text'>
						<Input type='text' valueKey={'fullName'} titleCallback={obj => `${obj.fullName}`} />
					</FormItem>
				)}
				<FormItem label={t('common.request')} name='offTypeAcronym' type='select'>
					<Select onChange={handleOnchange}>
						{types?.map((type, index) => {
							return (
								<Option value={type.acronym} key={index}>
									{type?.name}
								</Option>
							);
						})}
					</Select>
				</FormItem>
			</div>

			<div className='modal-content-form'>
				{requestType && requestType === REQUESTS_TYPE.checkin ? (
					<CheckInForm request={requestType} form={form} />
				) : requestType && requestType === REQUESTS_TYPE.checkout ? (
					<CheckInForm request={requestType} form={form} />
				) : requestType && requestType === REQUESTS_TYPE.late ? (
					<LateWork request={requestType} form={form} />
				) : requestType && requestType === REQUESTS_TYPE.early ? (
					<LateWork request={requestType} form={form} />
				) : requestType && requestType === REQUESTS_TYPE.Re ? (
					<RemoteForm request={requestType} form={form} />
				) : requestType && requestType === REQUESTS_TYPE.OT ? (
					<OverTimeForm request={requestType} form={form} />
				) : requestType && requestType === REQUESTS_TYPE.CP ? (
					<ReparationForm request={requestType} form={form} />
				) : (
					<VacationSalary request={requestType} form={form} />
				)}
			</div>
			<FormItem
				name='reason'
				label={t('common.reason')}
				type='text'
				rules={[
					{ required: true, message: t('message.exactlyReason') },
					{
						validator: (_, value) =>
							value?.trim() === '' ? Promise.reject(new Error(t('message.exactlyReason'))) : Promise.resolve()
					}
				]}
				min='5'>
				<TextArea />
			</FormItem>

			<FormItem
				name='acceptedBy'
				label={t('user.personApproved')}
				tooltip={t('user.personApprovedExplanation')}
				type='text'
				rules={[{ required: true, message: t('user.personApprovedMessage') }]}>
				<UserSelect
					keyName={'acceptedBy'}
					url={`${URL_ACCEPTED_BY}/${username}/leaders`}
					valueKey={'userName'}
					titleCallback={obj => {
						return `${obj.firstName} ${obj.lastName}`;
					}}
				/>
			</FormItem>

			<FormItem name='reviewer' label={t('user.reviewer')} tooltip={t('user.reviewerExplanation')} type='text'>
				<UserSelect
					keyName={'reviewer'}
					url={`${URL_ACCEPTED_BY}/${username}/reviewers`}
					valueKey={'userName'}
					titleCallback={obj => {
						return `${obj.firstName} ${obj.lastName}`;
					}}
				/>
			</FormItem>

			<FormItem name='notiToPerson' label={t('user.notiToPerson')} type='text'>
				<UserSelect
					keyName={'notiToPerson'}
					mode='multiple'
					url={`${URL_ACCEPTED_BY}/${username}/leaders`}
					valueKey={'userName'}
					titleCallback={obj => {
						return `${obj.firstName} ${obj.lastName}`;
					}}
				/>
			</FormItem>
			<Form.Item name={'evidences'} noStyle hidden />
			{requestType !== REQUESTS_TYPE.CP ? (
				<FormItem shouldUpdate noStyle>
					{() => {
						const currentPatchPhotos = form.getFieldValue(['evidences']) || [];
						return (
							<>
								<FormImage currentPatchPhotos={currentPatchPhotos} isAdd={isAdd} />
							</>
						);
					}}
				</FormItem>
			) : undefined}
		</ModalForm>
	);
}

export default FormModal;
