import { Menu } from 'antd';
import { menuApis } from '../../api/menuApis';
import { useQuery } from '@tanstack/react-query';
import { useUserFlattenRoutes } from '../../routes/useUserRoutes';
import { useMemo, useState } from 'react';
import { calculateKeyByPathname, calculateMenuUI } from './AppSidebar.utils';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AppSidebar = () => {
	const { pathname } = useLocation();
	const { i18n } = useTranslation();

	const { userRoles, sidebarUserRoles } = useUserFlattenRoutes();

	const [selectedKey, setSelectedKey] = useState(calculateKeyByPathname(pathname, userRoles));

	const { data: dataMenu } = useQuery({
		queryKey: ['menu'],
		queryFn: menuApis.getMenus
	});

	const items = useMemo(() => {
		if (!dataMenu) {
			return [];
		}
		return calculateMenuUI(dataMenu.data, sidebarUserRoles, i18n.language);
	}, [dataMenu, sidebarUserRoles]);

	const onClickToItem = ({ key }) => {
		setSelectedKey(key);
	};

	const defaultOpenKeys = useMemo(() => {
		if (selectedKey) {
			const pattern = /^(.*?)\.[^.]*$/;
			const result = selectedKey.match(pattern)?.[1];
			if (result) {
				return [result];
			}
		}
		return undefined;
	}, [selectedKey]);

	return (
		<Menu
			theme='dark'
			mode='inline'
			items={items}
			onClick={onClickToItem}
			selectedKeys={[selectedKey]}
			defaultOpenKeys={defaultOpenKeys}
		/>
	);
};
