import React, { memo } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import './tableDetail.scss';
import showModalConfirm from '../notifications/showModalConfirm';
import { useDispatch } from 'react-redux';
import { openModal, setOpenModify, setCloseModify, setRequestType } from '../../app/reducers/formSlice';
import i18n from '../../translation/i18n';

function TableDetails({ id, fullName, handleDelete, disabledDelete, isModify, typeAcronym, isRequest = false }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleDeleteClick = async () => {
		if (typeof handleDelete === 'function') {
			try {
				await handleDelete();
				isRequest
					? message.success(i18n.t('request.deleteUserMessage', { val: t('common.request') }))
					: message.success(i18n.t('request.deleteUserMessage', { val: `${fullName}` })),
					{
						action: t('common.delete')
					};
			} catch (error) {
				console.log('error :', error);
			}
		}
	};

	const showDeleteModal = () => {
		const title = isRequest ? t('message.modalUser', { val: t('common.request') }) : t('message.modalUser', { val: fullName });
		showModalConfirm({
			title,
			onOk: handleDeleteClick
		});
	};

	return (
		<div className='actions'>
			<Button
				type='link'
				onClick={() => {
					dispatch(openModal(id));
					dispatch(setRequestType(typeAcronym));
					dispatch(setOpenModify(isModify));
				}}>
				{t('common.detail')}
			</Button>
			<Button disabled={disabledDelete} type='link' danger onClick={showDeleteModal}>
				{t('common.delete')}
			</Button>
		</div>
	);
}

export default memo(TableDetails);
