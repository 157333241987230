import React, { memo, useCallback, useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import EyeMenu from './EyeMenu';

function ToggleCols({ setCols, cols }) {
	const [visible, setVisible] = useState(false);
	const [selectedKeys, setSelectedKeys] = useState([]);

	const handleOk = useCallback(
		cols => {
			setCols(cols);
			setVisible(false);
		},
		[setCols]
	);

	return (
		<Dropdown
			open={visible}
			onOpenChange={setVisible}
			getPopupContainer={triggerNode => triggerNode.parentNode}
			placement='bottomRight'
			dropdownRender={() => {
				return <EyeMenu cols={cols} onOk={handleOk} />;
			}}
			trigger={['click']}
		>
			<EyeOutlined className='util-bar__item' />
		</Dropdown>
	);
}

export default memo(ToggleCols);
