import { SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';
import { useFetch, useFilter } from '../../common/hooks';
const { Option } = Select;
export const FilterSelect = ({ placeHolder, name, url, displayKey, valueKey, customLabel, ...rest }) => {
	const { data } = useFetch({ url, withQuery: false });

	const { filter, setFilter } = useFilter();

	const handleOnChange = value => {
		if (value) {
			setFilter({
				...filter,
				keyword: value
			});
		} else {
			delete filter?.keyword;
			setFilter({ ...filter });
		}
	};

	const commonProps = {
		value: filter?.[name],
		onChange: handleOnChange
	};

	return (
		<Select {...commonProps} placeholder={placeHolder} allowClear suffixIcon={<SearchOutlined />} {...rest}>
			{data?.map(item => {
				if (displayKey && valueKey) {
					return (
						<Option key={item?.[valueKey]} value={item?.[valueKey]}>
							{customLabel ? `${item?.firstName} ${item?.lastName}` : item?.[displayKey]}
						</Option>
					);
				}
				return null;
			})}
		</Select>
	);
};
