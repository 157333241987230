import {DatePicker, Form, Input, Radio, Select} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {userRequestApi} from '../../../api/userRequestApi';
import UserSelect from '../../../components/CommonFields/UserSelect';
import FormItem from '../../../components/FormItem';
import ModalForm from '../../../components/Modal';
import {allRole, DATE_FORMAT, ROLE} from '../../../constant';
import {URL_ACCEPTED_BY} from '../../../constant/common/api';
import moment from "moment";

function UserFormModal({mutate}) {
	const {t} = useTranslation();
	const [form] = Form.useForm();

	const {id: userData, visible} = useSelector(state => state.form);
	console.log(userData);
	const {roles} = useSelector(state => state.auth);

	useEffect(() => {
		form.setFieldsValue({...userData, onBoard: userData?.onBoard ? moment(userData.onBoard) : undefined});
	}, [visible]);

	const handleSubmit = async value => {
		await userRequestApi.editUserSetting(`${userData?.username}`, {
			...value,
			onBoard: value?.onBoard?.format(DATE_FORMAT)
		});
		mutate({...value});
	};

	return (
		<ModalForm form={form} title={t('common.detail')} onSubmit={handleSubmit} disabledFeature>
			<Form.Item name={'fullName'} label={t('admin.fullName')} rules={[{required: true}]}>
				<Input/>
			</Form.Item>

			<Form.Item name='username' label={t('admin.userName')} disabled={true} rules={[{required: true}]}>
				<Input disabled/>
			</Form.Item>

			<Form.Item
				name='macId'
				label={t('admin.macId')}
				rules={roles?.includes(ROLE.COSIC_ADMIN) ? [{required: false}] : [{required: true}]}
			>
				<Input/>
			</Form.Item>

			<Form.Item name='macIdUnifi' label={t('admin.macIdUnifi')}>
				<Input/>
			</Form.Item>

			<Form.Item name='roles' label={t('admin.role')}>
				<Select allowClear mode='multiple' options={allRole} showArrow placeholder='phân quyền cho người dùng'/>
			</Form.Item>

			<Form.Item name='isLeader' label='Leader' type='text' disabled={true} rules={[{required: true}]}>
				<UserSelect
					url={`${URL_ACCEPTED_BY}/${userData?.username}/leaders`}
					valueKey={'userName'}
					titleCallback={(obj) => {
						return `${obj.firstName} ${obj.lastName}`;
					}}
				/>
			</Form.Item>

			<FormItem name='isPartTime' label={('Nhân viên')} form={form}>
				<Radio.Group name='radiogroup'>
					<Radio value={true}>{t('Part-time')}</Radio>
					<Radio value={false}>{t('Full-time')}</Radio>
				</Radio.Group>
			</FormItem>

			<FormItem name='active' label={t('common.status')}>
				<Radio.Group name='radiogroup'>
					<Radio value={true}>{t('admin.active')}</Radio>
					<Radio value={false}>{t('admin.deactivate')}</Radio>
				</Radio.Group>
			</FormItem>

			<FormItem name='onBoard' label={('Ngày kí hợp đồng chính thức')} form={form}>
				<DatePicker format={DATE_FORMAT}/>
			</FormItem>
		</ModalForm>
	);
}

export default UserFormModal;
