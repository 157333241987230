import moment from 'moment';
import qs from 'query-string';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {useFetch, useFilter} from '../../../common/hooks';
import ETable from '../../../components/ETable';
import {DATE_FORMAT, DEFAULT_LIMIT, DEFAULT_PAGE, MONTH, URL, WORKTIME, YEAR} from '../../../constant';
import './styles.scss';
import useCreateColumns from './useColumns';

import {GET_DAY_OF_MONTH, getWorkingDay} from '../../../utils/dateTime';

function TimeKeeping({isSync}) {
	const {t} = useTranslation();
	const columns = useCreateColumns();
	const location = useLocation();
	const { filter } = useFilter();
	const queryParams = qs.parse(location.search);
	const [month, setMonth] = useState();
	const [year, setYear] = useState();
	const [day, setDay] = useState();
	const [date] = useState(moment(Date.now()).format(DATE_FORMAT));
	const [listParams, setListParams] = useState({
		date: queryParams.date ? queryParams.date : date,
		currentPage: DEFAULT_PAGE,
		limit: DEFAULT_LIMIT,
		keyword: '',
		...queryParams
	});
	useEffect(() => {
		const date = new Date();
		setMonth(moment(date).format(MONTH));
		setYear(moment(date).format(YEAR));
	}, []);

	useEffect(() => {
		const dayInMonth = GET_DAY_OF_MONTH(year, month);
		setDay(dayInMonth);
	}, [month, year, date]);
	const { data, loading } = useFetch({ url: URL.TIMEKEEPING_API_URL });
	const dataSource = data?.data?.[0];
	return (
		<ETable
			header={
				<div className='header-table'>
					<h3>
						{t('common.total_work')}: {`${dataSource?.totalWorkDays?.toFixed(1)}/${getWorkingDay(filter?.date)}`}
					</h3>
					<h3>
						{t('common.totalWageFine')}: {`${dataSource?.totalWageFine?.toFixed(1)}`}
					</h3>
					<h3>
						{t('common.totalTimeLate')}: {dataSource?.totalTimeGoLateWentEarly?.toFixed(2)}
					</h3>
				</div>
			}
			className='historyTable'
			rowKey='dateAt'
			columns={columns}
			dataSource={dataSource?.workDays}
			rowClassName={record => (record.workTime <= WORKTIME ? 'warning' : '')}
			sticky
			data={data}
			day={day}
			year={year}
			month={date?.slice(3, 5)}
			listParam={listParams}
			setListParam={setListParams}
			pagination={{
				hideOnSinglePage: true,
				pageSize: data?.limit
			}}
			loading={loading}
			isSync={isSync}
		/>
	);
}

export default TimeKeeping;
