import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	timeOT: 0
};

export const timeSlice = createSlice({
	name: 'time',
	initialState,
	reducers: {
		setTimeOT: (state, { payload }) => {
			state.timeOT = payload;
		}
	}
});

export const { setTimeOT } = timeSlice.actions;

export default timeSlice.reducer;
