import { displayLeaderApprove, displayReview, displaySuperAdmin, ROLE } from '../constant';
import EditedHistory from '../pages/admin/EditedHistory';
import TimeKeeping from '../pages/user/TimeKeeping';
import Request from '../pages/user/Request';
import Statistic from '../pages/leader/Statistic';
import Wysiwyg from '../pages/user/Regulation';
import TimeKeepingTable from '../pages/admin/TimeKeepingTable';
import GeneralStatistic from '../pages/admin/GeneralStatistic';
import PersonalStatistic from '../pages/admin/PersonalStatistic';
import SetupUser from '../pages/admin/Setting/SetupUser';
import Setting from '../pages/admin/Setting';
import ConfigTime from '../pages/admin/Setting/ConfigTime/ConfigTime';
import ConfigLeaveMode from '../pages/admin/Setting/ConfigLeaveMode/ConfigLeaveMode';
import ApproveAdmin from '../pages/admin/Approve';
import AntNoData from '../pages/admin/PersonalStatistic/AntNoData';
import { Navigate } from 'react-router-dom';
import Accountant from '../pages/accountant';

export const Roles = [
	{
		index: true,
		element: <Navigate to='time_keeping' />
	},
	{
		path: 'time_keeping',
		element: <TimeKeeping />
	},
	{ path: 'request', element: <Request /> },
	{
		path: 'approve',
		permissionKey: displayLeaderApprove,
		element: <ApproveAdmin />
	},
	{
		path: 'leader_statistic',
		permissionKey: [ROLE.COSIC_LEADER],
		element: <Statistic />
	},

	{
		path: 'history',
		element: <EditedHistory />
	},
	{
		path: 'statistic',
		permissionKey: displayReview
	},
	{
		path: 'statistic/timekeeping_table',
		permissionKey: displayReview,
		element: <TimeKeepingTable />
	},
	{
		path: 'statistic/general_statistic',
		permissionKey: displayReview,
		element: <GeneralStatistic />
	},

	{
		path: 'statistic/personal_statistic/ant',
		permissionKey: displayReview,
		element: <AntNoData />
	},
	{
		path: 'statistic/personal_statistic',
		permissionKey: displayReview,
		element: <PersonalStatistic />
	},
	{
		path: 'setting',
		permissionKey: displaySuperAdmin
	},
	{
		path: 'setting/setup_user',
		permissionKey: displaySuperAdmin,
		element: <SetupUser />
	},
	{
		path: 'setting/setup_link',
		permissionKey: displaySuperAdmin,
		element: <Setting />
	},
	{
		path: 'setting/config_time',
		permissionKey: displaySuperAdmin,
		element: <ConfigTime />
	},
	{
		path: 'setting/config_leaveMode',
		permissionKey: displaySuperAdmin,
		element: <ConfigLeaveMode />
	},
	{
		path: 'regulation_leave',
		element: <Wysiwyg />
	},
	{
		path: 'accountant',
		permissionKey: [ROLE.COSIC_ACCOUNTANT],
		element: <Accountant />
	}
];
